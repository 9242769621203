/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {createRef, FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as jewelleryShowcaseVideos from '../redux/JewelleryShowcaseVideosRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {DeleteOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons'
import {
  changeBlackListData,
  changeStatusJewelleryShowcaseVideoData,
  deleteJewelleryShowcaseVideoData,
  editJewelleryShowcaseVideoData,
  getSearchJewelleryShowcaseVideosData,
  getJewelleryShowcaseVideosData,
  createJewelleryShowcaseVideoData,
} from '../redux/JewelleryShowcaseVideosCRUD'
import {config} from '../../../comman/constants'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {InputText} from '../../../modules/comman/formComponents/InputText'
// import {EditJewelleryShowcaseVideoForm} from './EditJewelleryShowcaseVideoForm'
// import FtpDetails from './FtpDetails'
// import {EditUserForm} from './EditUserForm'
// import {IClientFormValues} from './CreateClientForm'

interface IRecordsTableProps {
  jewelleryShowcaseVideosData: any
  jewelleryShowcaseVideosDataTotal: number
  jewelleryShowcaseVideosLoading: boolean
  setListJewelleryShowcaseVideosData: (jewelleryShowcaseVideosData: any) => void
  setListJewelleryShowcaseVideosDataTotal: (jewelleryShowcaseVideosDataTotal: number) => void
  setJewelleryShowcaseVideosLoading: (jewelleryShowcaseVideosLoading: boolean) => void
  history?: History<LocationState>
  loginUser: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  jewelleryShowcaseVideosSelectedData: any
  ftpDataState: any
  copied: any
  copyCell: any
  showAddVideoModal: any
  videoFileName: any
  imageFileName: any
  description: any
}

type PathParamsType = {
  param1: string
}
const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

class ListJewelleryShowcaseVideosData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      jewelleryShowcaseVideosSelectedData: null,
      ftpDataState: null,
      copied: false,
      copyCell: {},
      showAddVideoModal: false,
      videoFileName: '',
      imageFileName: '',
      description: '',
    }
  }
  uploadRef = createRef<any>()
  uploadVidRef = createRef<any>()

  public uploadMedia = async (selectedData?: any) => {
    const {startIndex, limitNumber, imageFileName, videoFileName, description} = this.state

    console.log({selectedData})
    if (imageFileName || videoFileName || selectedData?.id) {
      console.log('!!!!!!!!!!!uploadMedia', imageFileName, videoFileName, description)
      if (selectedData?.id) {
        console.log({
          selectedData: selectedData,
          file_name: videoFileName,
          description: description,
          thumbnail_name: imageFileName,
        })
        await editJewelleryShowcaseVideoData({
          id: selectedData?.id,
          file_name: videoFileName ? videoFileName : selectedData?.file_name,
          description: description,
          thumbnail_name: imageFileName ? imageFileName : selectedData?.thumbnail_name,
        })
      } else {
        await createJewelleryShowcaseVideoData({
          file_name: videoFileName,
          description: description,
          thumbnail_name: imageFileName,
        })
      }
    }
    //close modal popup
    // this.setState({
    //   showAddVideoModal: false,
    // })
    this.closeModal()
    this.listJewelleryShowcaseVideosData(startIndex, limitNumber)
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListJewelleryShowcaseVideosData(pagination)
    )
  }

  public getListJewelleryShowcaseVideosData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listJewelleryShowcaseVideosData(start, end)
      }
    )
  }

  public listJewelleryShowcaseVideosData = async (start: number, end: any) => {
    const {setJewelleryShowcaseVideosLoading} = this.props
    try {
      const {setListJewelleryShowcaseVideosData, setListJewelleryShowcaseVideosDataTotal} =
        this.props
      await setJewelleryShowcaseVideosLoading(true)
      let jewelleryShowcaseVideosData = await getJewelleryShowcaseVideosData(start, end)
      if (jewelleryShowcaseVideosData.data) {
        await setListJewelleryShowcaseVideosData(jewelleryShowcaseVideosData.data)
        await setListJewelleryShowcaseVideosDataTotal(jewelleryShowcaseVideosData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setJewelleryShowcaseVideosLoading(false)
    }
  }

  public onCreateJewelleryShowcaseVideo = (item?: any) => {
    this.setState({
      showAddVideoModal: item ? item : true,
      description: item?.description,
    })
  }

  public deleteJewelleryShowcaseVideo = async (item: any) => {
    const {setJewelleryShowcaseVideosLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setJewelleryShowcaseVideosLoading(true)
      await deleteJewelleryShowcaseVideoData(item.id)

      Toaster({
        type: 'success',
        title: 'JEWELLERY SHOWCASE VIDEOS',
        description: 'Deleted SuccessFully',
      })
      this.listJewelleryShowcaseVideosData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setJewelleryShowcaseVideosLoading(false)
    }
  }

  public closeModal = async () => {
    this.setState({
      showAddVideoModal: false,
      description: '',
      videoFileName: '',
      imageFileName: '',
    })
    if (this.uploadRef.current) {
      this.uploadRef.current.clearFiles()
    }
    if (this.uploadVidRef.current) {
      this.uploadVidRef.current.clearFiles()
    }
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Video',
      key: 'Video',
      width: 150,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div className='d-flex'>
            {item.video_url ? (
              <video src={item.video_url} controls width='200' height='150' className='rounded' />
            ) : (
              <span className='text-muted'>No Video</span>
            )}
          </div>
        )
      },
    },
    {
      title: 'Thumbnail Image',
      key: 'Image',
      width: 150,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div className='d-flex'>
            {item.thumbnail_url ? (
              <a href={item.thumbnail_url} target='_blank' rel='noopener noreferrer'>
                <img src={item.thumbnail_url} height='150' className='rounded' />
              </a>
            ) : (
              <span className='text-muted'>No Image</span>
            )}
          </div>
        )
      },
    },
    {
      title: 'Description',
      key: 'description',
      width: 150,
      render: (text: any, item: any, index: number) => {
        // return <div>{item.note ? item.note : '-'}</div>
        return (
          <Tooltip placement='bottom' title={item && item?.description ? item?.description : '-'}>
            <div className='d-inline-block text-truncate' style={{maxWidth: '200px'}}>
              {' '}
              {item?.description ? item?.description : '-'}{' '}
            </div>
          </Tooltip>
        )
      },
    },
    {
      title: 'Actions',
      width: 180,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete jewelleryShowcaseVideos api
                    this.deleteJewelleryShowcaseVideo(item)
                  }}
                  title='Are you sure you want to delete jewelleryShowcaseVideos?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>

            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Preview'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.onCreateJewelleryShowcaseVideo(item)
                    // this.showPreviewModal(item)
                    // this.navigateToPreviewOrderPage(item)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {
      jewelleryShowcaseVideosData,
      jewelleryShowcaseVideosDataTotal,
      jewelleryShowcaseVideosLoading,
      loginUser,
    } = this.props
    const {role} = loginUser

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header' style={{justifyContent: 'flex-end'}}>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateJewelleryShowcaseVideo()
                  }}
                >
                  Add Jewellery Showcase Video
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {id: any}) => record.id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  paginationConfig={{
                    total: jewelleryShowcaseVideosDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={jewelleryShowcaseVideosLoading}
                  dataSource={jewelleryShowcaseVideosData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={false}
                  // scrollWidth={role == 'super_admin' ? 1350 : 1200}
                />
                <div>
                  {' '}
                  <CommanModal
                    show={this.state.showAddVideoModal ? true : false}
                    handleClose={() => {
                      this.closeModal()
                    }}
                    title={'Add video'}
                    submitText={'Save Changes'}
                    height={600}
                  >
                    <>
                      <label style={{marginTop: '1rem'}}>Video :</label>
                      <div
                        style={{
                          display: this.state.showAddVideoModal?.id ? 'flex' : '',
                          gap: '2rem',
                        }}
                      >
                        <InputSingleFileUpload
                          buttonLabel={'Choose Video File'}
                          useModal={false}
                          ref={this.uploadVidRef}
                          fileLists={[]}
                          emptyListOnUpload={false}
                          onBlur={() => {}}
                          error={() => {}}
                          touched={() => {}}
                          name='file_name'
                          input={{
                            id: 'import-video',
                            name: 'video',
                            action: config.addJewelleryVideoPath,
                            headers: {
                              authorization: 'authorized-text',
                            },
                            accept: '.mp4, .mov, .avi, .mkv, .webm',
                            acceptMimeTypes: [
                              'video/mp4',
                              'video/quicktime',
                              'video/x-msvideo',
                              'video/x-matroska',
                              'video/webm',
                            ],
                            mediaType: config.importStockUploadKey,
                            mediaModuleType: config.importStockUploadKey,
                          }}
                          onUpload={(file_name: string) => {
                            this.setState({videoFileName: file_name})
                          }}
                        />
                        {this.state.showAddVideoModal?.video_url && (
                          <video
                            key={this.state.videoFileName || this.state.showAddVideoModal.video_url}
                            width='50%'
                            height='auto'
                            controls
                            style={{marginTop: '1rem'}}
                          >
                            <source
                              src={
                                this.state.videoFileName
                                  ? `${appAPIURL}/jewellery/video/${this.state.videoFileName}`
                                  : this.state.showAddVideoModal.video_url
                              }
                              type='video/mp4'
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </div>

                      <label style={{marginTop: '2rem'}}>Image :</label>
                      <div
                        // className='d-flex'
                        style={{
                          display: this.state.showAddVideoModal?.id ? 'flex' : '',
                          gap: '2rem',
                        }}
                      >
                        <InputSingleFileUpload
                          buttonLabel={'Choose Image File'}
                          useModal={false}
                          ref={this.uploadRef}
                          emptyListOnUpload={false}
                          fileLists={[]}
                          onBlur={() => {}}
                          error={() => {}}
                          touched={() => {}}
                          name='file_name'
                          input={{
                            id: 'image',
                            name: 'image',
                            action: config.addJewelleryImgPath,
                            headers: {
                              authorization: 'authorized-text',
                            },
                            accept: '.png,.jpeg,.jpg,.gif,.webp',
                            acceptMimeTypes: ['image/png', 'image/jpeg', 'image/jpg'],
                            mediaType: 'png|jpeg|jpg|gif|webp',
                            mediaModuleType: 'png|jpeg|jpg|gif|webp',
                          }}
                          onUpload={(file_name: string) => {
                            this.setState({imageFileName: file_name})
                          }}
                        />
                        {this.state.showAddVideoModal.thumbnail_url && (
                          <img
                            src={
                              this.state.imageFileName
                                ? `${appAPIURL}/jewellery/video/thumbnail/${this.state.imageFileName}`
                                : this.state.showAddVideoModal.thumbnail_url
                            }
                            alt='Uploaded Image'
                            style={{width: '50%', height: 'auto', marginTop: '1rem'}}
                          />
                        )}
                      </div>

                      <label style={{marginTop: '3rem'}}>Description :</label>
                      <input
                        placeholder={'Description'}
                        type={'text'}
                        className={'form-control'}
                        value={this.state.description}
                        autoComplete='off'
                        onChange={(event: any) => {
                          this.setState({description: event.target.value})
                        }}
                      />

                      <div className='float-right mr-5' style={{marginTop: '2rem'}}>
                        <button
                          type='button'
                          className='btn btn-primary btn-sm'
                          onClick={() => this.uploadMedia(this.state.showAddVideoModal)}
                        >
                          {this.state.showAddVideoModal?.id ? 'Update' : 'Create'}
                        </button>
                      </div>
                    </>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  jewelleryShowcaseVideosData: state.jewelleryShowcaseVideos.jewelleryShowcaseVideosData,
  jewelleryShowcaseVideosDataTotal: state.jewelleryShowcaseVideos.jewelleryShowcaseVideosDataTotal,
  jewelleryShowcaseVideosLoading: state.jewelleryShowcaseVideos.jewelleryShowcaseVideosLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListJewelleryShowcaseVideosData: (jewelleryShowcaseVideosData: any) => {
      dispatch(
        jewelleryShowcaseVideos.actions.setJewelleryShowcaseVideosData(jewelleryShowcaseVideosData)
      )
    },
    setListJewelleryShowcaseVideosDataTotal: (jewelleryShowcaseVideosDataTotal: number) => {
      dispatch(
        jewelleryShowcaseVideos.actions.setJewelleryShowcaseVideosDataTotal(
          jewelleryShowcaseVideosDataTotal
        )
      )
    },
    setJewelleryShowcaseVideosLoading: (jewelleryShowcaseVideosLoading: boolean) => {
      dispatch(
        jewelleryShowcaseVideos.actions.setJewelleryShowcaseVideosLoading(
          jewelleryShowcaseVideosLoading
        )
      )
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListJewelleryShowcaseVideosData))
