/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  show: boolean
  handleClose: () => void
  buttonText?: string
  title: string
  onSubmit?: () => void
  submitText?: string
  BarrierDismissible?: boolean
  isFooter?: boolean
  height?: any
  isScrollable?: boolean
  modalSize?: string
  modalBodyHeight?: number
}

const CommanModal: React.FC<Props> = ({
  show,
  handleClose,
  children,
  buttonText,
  title,
  onSubmit,
  submitText,
  BarrierDismissible = true,
  isFooter = false,
  height = 'auto',
  modalBodyHeight = 'auto',
  isScrollable = false,
  modalSize = 'modal-lg',
}) => {
  return (
    <div
      onClick={() => {
        if (BarrierDismissible) {
          handleClose()
        }
      }}
    >
      {/* Button trigger modal*/}
      {/* <button
        type='button'
        className='btn btn-primary'
        data-toggle='modal'
        data-target='#exampleModalScrollable'
      >
        {buttonText}
      </button> */}

      {/* Modal*/}
      <div
        className={show ? 'modal show fade' : 'modal fade'}
        id='exampleModalScrollable'
        tabIndex={-1}
        role='dialog'
        aria-labelledby='staticBackdrop'
        style={{display: show ? 'block' : 'none'}}
        aria-modal={show ? 'true' : 'false'}
      >
        <div
          // className='modal-dialog modal-dialog-scrollable modal-lg'
          className={`modal-dialog ${isScrollable && 'modal-dialog-scrollable'} ${
            modalSize ? modalSize : 'modal-lg'
          }`}
          onClick={(e) => {
            e.stopPropagation()
          }}
          role='document'
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                {title}
              </h5>
              <button
                type='button'
                onClick={handleClose}
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <i aria-hidden='true' className='ki ki-close' />
              </button>
            </div>
            <div
              className='modal-body'
              style={{height: height ? `${height}px` : '300px', overflow: 'auto'}}
            >
              {children}
            </div>
            {isFooter && (
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light-primary font-weight-bold'
                  data-dismiss='modal'
                  onClick={handleClose}
                >
                  Close
                </button>
                {submitText ? (
                  <button
                    onClick={onSubmit}
                    type='button'
                    className='btn btn-primary font-weight-bold'
                  >
                    {submitText}
                  </button>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {CommanModal}

// Modal Link
// https://preview.keenthemes.com/metronic/demo4/features/bootstrap/modal.html

// Example of calling Modal
// <CommanModal
//     show={true}
//     handleClose={() => {
//       console.log('!!!!!close button clicked!!!!!')
//     }}
//     onSubmit={() => {
//       console.log('!!!!!submit button clicked!!!!!')
//     }}
//     title={'Launch Demo Modal'}
//     submitText={'Save Changes'}
//     buttonText={'Launch Demo Modal'}
//   >
//     <div>
//       <div>
//         Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
//         been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
//         galley of type and scrambled it to make a type specimen book. It has survived not only five
//         centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
//         It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
//         passages, and more recently with desktop publishing software like Aldus PageMaker including
//         versions of Lorem Ipsum.
//       </div>
//     </div>
//   </CommanModal>
