import * as React from 'react'
import {RouteComponentProps} from 'react-router'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as inventory from '../redux/InventoryRedux'
import {History, LocationState} from 'history'
import {Tag} from 'antd'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
  returnStockString,
} from '../../../comman/helper'
import {config} from '../../../comman/constants'
import {KpiCardComponent} from '../../../modules/comman/components/KpiCardComponent'

interface IViewInventoryProps {
  singleInventoryData?: any
  inventoryLoading: boolean
  inventoryCartData?: any
  setInventoryCartData: (inventoryCartData: any) => void
  setInventoryLoading: (inventoryLoading: boolean) => void
  role?: any
}

interface IViewInventoryState {
  data: any
  isLoading: boolean
  roleData: any
}

type PathParamsType = {
  id: string
}

class ViewInventoryForm extends React.Component<
  RouteComponentProps<PathParamsType> & IViewInventoryProps,
  IViewInventoryState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      roleData: [],
    }
  }

  unusedKey = [
    '__v',
    '_deleted',
    '_id',
    'updatedAt',
    'createdAt',
    'is_active',
    'diamond_image',
    'diamond_video',
    'status',
  ]

  unusedKeyVendor = ['discounts', 'price_per_caret', 'final_price', 'sku_prefix', 'stock_margin']
  priceItemKeys = ['final_price', 'rap_per_caret', 'final_price_ori']
  upperCaseKey = [
    'stock_id',
    'color',
    'cut',
    'shape',
    'clarity',
    'polish',
    'symmetry',
    'availability',
    'brand',
    'culet_condition',
    'diamond_type',
    'girdle_condition',
    'treatment',
    'fluorescence_intensity',
  ]

  componentDidMount = () => {
    const {match} = this.props
  }

  public addSelectedItemsToCart = async () => {
    const {singleInventoryData, inventoryCartData, setInventoryLoading, setInventoryCartData} =
      this.props
    try {
      await setInventoryLoading(true)

      let inventoryFilterIndex = -1
      if (inventoryCartData) {
        inventoryFilterIndex = inventoryCartData.findIndex(
          (item: any) => item.stock_id === singleInventoryData.stock_id
        )
      }
      if (
        singleInventoryData &&
        Object.keys(singleInventoryData).length >= 1 &&
        inventoryFilterIndex === -1
      ) {
        let tempInvItem: any = [
          {
            _id: singleInventoryData._id,
            stock_id: singleInventoryData.stock_id,
            stock_data: singleInventoryData,
          },
        ]
        await setInventoryCartData([].concat(inventoryCartData || [], tempInvItem))

        Toaster({
          type: 'success',
          title: 'INVENTORY',
          description: 'Item Added to cart',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'INVENTORY',
          description: 'Item is already added to the cart',
        })
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public render() {
    const {singleInventoryData} = this.props

    return (
      <div>
        {singleInventoryData && (
          <div className='row g-5 g-xl-8'>
            {singleInventoryData.stock_id && (
              <KpiCardComponent
                icon='/media/icons/duotune/art/art002.svg'
                title='Stock Id'
                value={returnStockId(singleInventoryData.stock_id)}
                // path='/buy-request/list'
                background='white'
              />
            )}
            {singleInventoryData.status && (
              <KpiCardComponent
                icon='/media/icons/duotune/art/art002.svg'
                title='Status'
                value={singleInventoryData.status}
                // path='/buy-request/list'
                background='white'
              />
            )}
            {singleInventoryData.final_price && (
              <KpiCardComponent
                icon='/media/icons/duotune/art/art002.svg'
                title='Price'
                value={returnPriceInThousandFormatWithCurrencySign(
                  this.props.role === 'vendor'
                    ? singleInventoryData.final_price_ori
                    : singleInventoryData.final_price
                )}
                // path='/buy-request/list'
                background='white'
              />
            )}
            <KpiCardComponent
              icon='/media/icons/duotune/art/art002.svg'
              title='Stock Details'
              value={returnStockString(singleInventoryData)}
              // path='/buy-request/list'
              background='white'
            />
          </div>
        )}

        <div className='card card-custom'>
          {singleInventoryData ? (
            <>
              <div className='card-header'>
                <h3 className='card-title table-card-title' style={{width: '25%'}}>
                  {`Inventory Details (${
                    singleInventoryData && singleInventoryData.stock_id
                      ? returnStockId(singleInventoryData.stock_id)
                      : ''
                  })`}
                  {singleInventoryData && singleInventoryData.status && (
                    <>
                      <div className='ml-3'>
                        <Tag
                          color={
                            config.statusTagColor[singleInventoryData.status]
                              ? config.statusTagColor[singleInventoryData.status]
                              : 'default'
                          }
                        >
                          {singleInventoryData.status}
                        </Tag>
                      </div>
                    </>
                  )}
                </h3>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-2 font-weight-bold text-muted p-3'>
                    <label>{'Image:'}</label>
                  </div>
                  <div className='col-md-4'>
                    {singleInventoryData.diamond_image ? (
                      <a href={singleInventoryData.diamond_image} target='_blank'>
                        {singleInventoryData.diamond_image}
                      </a>
                    ) : (
                      <label>{'No Image Available'}</label>
                    )}
                  </div>

                  <div className='col-md-2 font-weight-bold text-muted p-3'>
                    <label>{'Video:'}</label>
                  </div>
                  <div className='col-md-4'>
                    {singleInventoryData.diamond_video ? (
                      <a href={singleInventoryData.diamond_video} target='_blank'>
                        {singleInventoryData.diamond_video}
                      </a>
                    ) : (
                      <label>{'No Video Available'}</label>
                    )}
                  </div>
                </div>
                <div className='row'>
                  {Object.keys(singleInventoryData).map((itemLabel: any, index: number) =>
                    !this.unusedKey.includes(itemLabel) &&
                    singleInventoryData[itemLabel] &&
                    !(this.props.role === 'vendor' && this.unusedKeyVendor.includes(itemLabel)) ? (
                      <>
                        <div className='col-md-2 font-weight-bold text-muted p-3' key={index}>
                          <label>
                            {this.props.role === 'vendor' &&
                            ['final_price_ori', 'price_per_caret_ori'].includes(itemLabel)
                              ? itemLabel.replace('_ori', '').replace(/_/g, ' ').toUpperCase()
                              : itemLabel.replace(/_/g, ' ').toUpperCase()}
                          </label>
                        </div>
                        <div className='col-md-4 p-3'>
                          <label>
                            {this.priceItemKeys.includes(itemLabel)
                              ? returnPriceInThousandFormatWithCurrencySign(
                                  singleInventoryData[itemLabel]
                                )
                              : this.upperCaseKey.includes(itemLabel)
                              ? singleInventoryData[itemLabel].toUpperCase()
                              : singleInventoryData[itemLabel]}
                          </label>
                        </div>
                      </>
                    ) : null
                  )}
                </div>
              </div>
              {/* {singleInventoryData &&
              singleInventoryData.status &&
              singleInventoryData.status === 'AVAILABLE' ? (
                <div className='card-footer'>
                  <div className='mr-5'>
                    <InputButtonComponent
                      onClick={() => {
                        this.addSelectedItemsToCart()
                      }}
                    >
                      Add to Cart
                    </InputButtonComponent>
                  </div>
                </div>
              ) : null} */}
            </>
          ) : (
            <div className='d-flex justify-content-center'>
              <div className='spinner-border'></div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  inventoryLoading: state.inventory.inventoryLoading,
  inventoryCartData: state.inventory.inventoryCartData,
  singleInventoryData: state.inventory.singleInventoryData,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setInventoryLoading: (inventoryLoading: boolean) => {
      dispatch(inventory.actions.setInventoryLoading(inventoryLoading))
    },

    setInventoryCartData: (inventoryCartData: any) => {
      dispatch(inventory.actions.setInventoryCartData(inventoryCartData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewInventoryForm))
