/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as vendors from '../redux/VendorsRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import {
  changeBlackListData,
  changeStatusVendorData,
  deleteVendorData,
  editVendorData,
  fetchVendorStockData,
  getSearchVendorsData,
  getVendorsData,
} from '../redux/VendorsCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {EditVendorForm} from './EditVendorForm'
import FtpDetails from './FtpDetails'
// import {EditUserForm} from './EditUserForm'
// import {IClientFormValues} from './CreateClientForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  vendorsData: any
  vendorsDataTotal: number
  vendorsLoading: boolean
  setListVendorsData: (vendorsData: any) => void
  setListVendorsDataTotal: (vendorsDataTotal: number) => void
  setVendorsLoading: (vendorsLoading: boolean) => void
  history?: History<LocationState>
  loginUser: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  vendorsSelectedData: any
  ftpDataState: any
  copied: any
}

type PathParamsType = {
  param1: string
}
class ListVendorsData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      vendorsSelectedData: null,
      ftpDataState: null,
      copied: false,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListVendorsData(pagination)
    )
  }

  public copyToClipboard = (data: any) => {
    navigator.clipboard.writeText(data?.trim())
    Toaster({
      type: 'success',
      title: `${data} Copied`,
    })
  }

  public callFetchApiStockApi = async (id: any) => {
    try {
      let fetchApiRes: any = await fetchVendorStockData(id)
      console.log({fetchApiRes})
      if (fetchApiRes && fetchApiRes.status === 200) {
        Toaster({
          type: 'success',
          title: 'VENDORS',
          description: fetchApiRes.data.message || fetchApiRes['message'],
        })
      } else {
        Toaster({
          type: 'error',
          title: 'VENDORS',
          description: fetchApiRes.data.message,
        })
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public getListVendorsData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listVendorsSearchData(start, end)
        } else {
          this.listVendorsData(start, end)
        }
      }
    )
  }

  public listVendorsData = async (start: number, end: any) => {
    const {setVendorsLoading} = this.props
    try {
      const {setListVendorsData, setListVendorsDataTotal} = this.props
      await setVendorsLoading(true)
      let vendorsData = await getVendorsData(start, end)
      if (vendorsData.data) {
        await setListVendorsData(vendorsData.data)
        await setListVendorsDataTotal(vendorsData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setVendorsLoading(false)
    }
  }

  public listVendorsSearchData = async (start: number, end: any) => {
    const {setVendorsLoading} = this.props
    try {
      const {setListVendorsData, setListVendorsDataTotal} = this.props
      const {searchText} = this.state

      await setVendorsLoading(true)
      let vendorsData = await getSearchVendorsData(start, end, searchText)
      if (vendorsData.data) {
        await setListVendorsData(vendorsData.data)
        await setListVendorsDataTotal(vendorsData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setVendorsLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listVendorsSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateVendor = () => {
    const {history} = this.props
    history.push('/vendors/create')
  }

  public deleteVendor = async (item: any) => {
    const {setVendorsLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setVendorsLoading(true)
      await deleteVendorData(item.id)

      Toaster({
        type: 'success',
        title: 'VENDORS',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listVendorsSearchData(startIndex, limitNumber)
        return
      }
      this.listVendorsData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setVendorsLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setVendorsLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setVendorsLoading(true)
      await changeStatusVendorData(item.id, checked)

      Toaster({
        type: 'success',
        title: 'VENDORS',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listVendorsSearchData(startIndex, limitNumber)
        return
      }
      this.listVendorsData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setVendorsLoading(false)
    }
  }

  public onChangeBlacklistStatus = async (item: any, checked: boolean) => {
    const {setVendorsLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setVendorsLoading(true)
      await changeBlackListData(item.id, checked)

      Toaster({
        type: 'success',
        title: 'VENDORS',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listVendorsSearchData(startIndex, limitNumber)
        return
      }
      this.listVendorsData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
      Toaster({
        type: 'error',
        title: 'VENDORS',
        description: 'Something Went Wrong',
      })
    } finally {
      await setVendorsLoading(false)
    }
  }

  public onEditSubmit = async (values: any) => {
    const {setVendorsLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setVendorsLoading(true)
      await editVendorData(values)

      Toaster({
        type: 'success',
        title: 'VENDORS',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listVendorsSearchData(startIndex, limitNumber)
        return
      }
      this.listVendorsData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setVendorsLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      vendorsSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      vendorsSelectedData: null,
    })
  }

  public showFtpDetailsModal = async (item: any) => {
    this.setState({
      ftpDataState: item,
    })
  }

  public closeFtpDetailsModal = async () => {
    this.setState({
      ftpDataState: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Full Name',
      key: 'Full Name',
      width: 150,
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.first_name && item.last_name
                ? `${item.first_name} ${item.last_name}`
                : item.first_name
                ? item.first_name
                : '-'}
            </strong>
            <button
              onClick={() => {
                this.copyToClipboard(`${item?.first_name} ${item?.last_name}`)
              }}
              style={{border: 'none', background: 'transparent', cursor: 'pointer'}}
            >
              <CopyOutlined style={{fontSize: '15px'}} />
            </button>
          </div>
        )
      },
    },
    {
      title: 'Company',
      key: 'company_name',
      width: 130,
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.company_name ? item.company_name : '-'}{' '}
            <button
              onClick={() => {
                this.copyToClipboard(item?.company_name)
              }}
              style={{border: 'none', background: 'transparent', cursor: 'pointer'}}
            >
              <CopyOutlined style={{fontSize: '15px'}} />
            </button>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      width: 245,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.email ? item.email : '-'}
            <button
              onClick={() => {
                this.copyToClipboard(item?.email)
              }}
              style={{border: 'none', background: 'transparent', cursor: 'pointer'}}
            >
              <CopyOutlined style={{fontSize: '15px'}} />
            </button>
          </div>
        )
      },
    },
    {
      title: 'Orders',
      key: 'order_count',
      render: (text: any, item: any, index: number) => {
        return <div>{item.order_count ? item.order_count : '0'}</div>
      },
    },
    {
      title: 'Buy Request',
      key: 'buy_request_count',
      render: (text: any, item: any, index: number) => {
        return <div>{item.buy_request_count ? item.buy_request_count : '0'}</div>
      },
    },
    {
      title: 'isBlacklisted',
      key: 'is_blacklisted',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <Switch
              checked={item.is_blacklisted}
              style={{
                backgroundColor: item.is_blacklisted ? PRIMARY_COLOR : '#bababa',
              }}
              onChange={(checked: boolean) => {
                //call onChange Method with item
                this.onChangeBlacklistStatus(item, checked)
              }}
            />
          </div>
        )
      },
    },
    {
      title: 'isActive',
      key: 'isActive',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_active != null ? (
              <Switch
                checked={item.is_active}
                style={{
                  backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
                }}
                onChange={(checked: boolean) => {
                  //call onChange Method with item
                  this.onChangeStatus(item, checked)
                }}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      width: 180,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete vendors api
                    this.deleteVendor(item)
                  }}
                  title='Are you sure you want to delete vendors?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='FTP Details'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showFtpDetailsModal(item)
                  }}
                />
              </Tooltip>
            </div>
            {item.vendor_type === 'API' && (
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='fetch stock'>
                  <Button
                    shape='circle'
                    icon={<LinkOutlined />}
                    onClick={() => {
                      this.showFtpDetailsModal({id: item?.id, type: 'fetchApi'})
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        )
      },
    },
  ]

  public render() {
    const {vendorsData, vendorsDataTotal, vendorsLoading, loginUser} = this.props
    const {searchText, vendorsSelectedData, ftpDataState} = this.state
    const {role} = loginUser

    const columnExists = (key: any) => {
      return this.columns.some((column) => column.key === key)
    }

    if (!columnExists('ftp_username') && role == 'super_admin') {
      this.columns.splice(4, 0, {
        title: 'Ftp Username',
        key: 'ftp_username',
        width: 180,
        render: (text: any, item: any, index: number) => {
          return (
            <div>
              <strong>{item.ftp_username ? item.ftp_username : '-'}</strong>
              <button
                onClick={() => {
                  this.copyToClipboard(item?.ftp_username)
                }}
                style={{border: 'none', background: 'transparent', cursor: 'pointer'}}
              >
                <CopyOutlined style={{fontSize: '15px'}} />
              </button>
            </div>
          )
        },
      })
    }

    if (!columnExists('ftp_password') && role == 'super_admin') {
      this.columns.splice(5, 0, {
        title: 'Ftp Password',
        key: 'ftp_password',
        width: 150,
        render: (text: any, item: any, index: number) => {
          return (
            <div>
              <strong>{item.ftp_password ? item.ftp_password : '-'}</strong>
              <button
                onClick={() => {
                  this.copyToClipboard(item?.ftp_password)
                }}
                style={{border: 'none', background: 'transparent', cursor: 'pointer'}}
              >
                <CopyOutlined style={{fontSize: '15px'}} />
              </button>
            </div>
          )
        },
      })
    }

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Vendors'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateVendor()
                  }}
                >
                  Add Vendor
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  paginationConfig={{
                    total: vendorsDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={vendorsLoading}
                  dataSource={vendorsData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={true}
                  scrollWidth={role == 'super_admin' ? 1350 : 1200}
                />
                <div>
                  <CommanModal
                    show={vendorsSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Vendors'}
                    submitText={'Save Changes'}
                    isScrollable={true}
                  >
                    <div>
                      {vendorsSelectedData && (
                        <EditVendorForm
                          data={vendorsSelectedData}
                          onSubmit={(values: any) => {
                            this.onEditSubmit(values)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                  <CommanModal
                    show={ftpDataState ? true : false}
                    handleClose={() => {
                      this.closeFtpDetailsModal()
                    }}
                    title={ftpDataState?.type === 'fetchApi' ? 'Api Response' : 'FTP DETAILS'}
                    submitText={'Save Changes'}
                    // isScrollable={true}
                  >
                    <div>
                      {ftpDataState && (
                        <FtpDetails
                          data={ftpDataState}
                          handleCancel={() => {
                            this.closeFtpDetailsModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  vendorsData: state.vendors.vendorsData,
  vendorsDataTotal: state.vendors.vendorsDataTotal,
  vendorsLoading: state.vendors.vendorsLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListVendorsData: (vendorsData: any) => {
      dispatch(vendors.actions.setVendorsData(vendorsData))
    },
    setListVendorsDataTotal: (vendorsDataTotal: number) => {
      dispatch(vendors.actions.setVendorsDataTotal(vendorsDataTotal))
    },
    setVendorsLoading: (vendorsLoading: boolean) => {
      dispatch(vendors.actions.setVendorsLoading(vendorsLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListVendorsData))
