import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputToggle} from '../../../modules/comman/formComponents/InputToggle'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {config} from '../../../comman/constants'
import {Switch} from 'antd'
import {editKycData} from '../redux/KycCRUD'

const PRIMARY_COLOR = config.buttonPrimaryColor

interface IRenderFormikForm {
  values: IBannerFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IBannerFormValues {
  credit_limit: number
  id: string
  //   is_verified: boolean
}

interface IKycProps {
  data?: any
  handleCancel?: any
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export class EditKycDocumentForm extends React.Component<IKycProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isImageLinked: false,
      image_url: '',
      file_name: '',
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: IBannerFormValues) => {
    const errors: any = {}

    if (!values.credit_limit && values.credit_limit !== 0) {
      errors.credit_limit = 'Credit Limit is Required'
    }

    return errors
  }

  public handleSubmit = async (values: IBannerFormValues, action: any) => {
    try {
      let kycData = await editKycData(values)
      if (kycData && kycData.status == 200) {
        Toaster({
          type: 'success',
          title: 'KYC',
          description: 'Updated Successfully',
        })
      }
    } catch (e) {
      console.log(e, '!!!!error in api call!!!!')
      Toaster({
        type: 'error',
        title: 'KYC',
        description: 'Something Went Wrong',
      })
      throw e
    } finally {
      this.props.handleCancel(values.id)
      this.setState({
        visible: false,
      })
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public openImageInNewTab = (url: any) => {
    if (window && window.open) {
      window.open(url, '_blank')
    }
  }

  public render() {
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  credit_limit: NaN,
                  id: this.props.data && this.props.data.id ? this.props.data.id : '',
                  //   is_verified: false,
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.credit_limit,
                              id: 'credit_limit',
                              name: 'credit_limit',
                            }}
                            placeholder='Enter Credit Limit'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('credit_limit', value)
                            }}
                            onBlur={handleBlur}
                            label='Credit Limit (in Dollars)'
                            error={errors}
                            touched={touched}
                            disabled={!this.props.data.trade_references?.length}
                          />
                        </div>
                        {!this.props.data.trade_references?.length && (
                          <p className='text-danger mt-2'>
                            Trade references are required to set a credit limit.
                          </p>
                        )}
                      </div>
                      <InputButtonComponent
                        disabled={!this.props.data.trade_references?.length}
                        onClick={handleSubmit}
                      >
                        Save
                      </InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
