/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getOrderData, getOrderFilterData} from '../redux/OrderCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as order from '../redux/OrderRedux'
import ListOrderData from './ListOrderData'
import {Tabs} from 'antd'
import {RootState} from '../../../../setup'
import {Spin} from 'antd'
import Select from 'react-select'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {Button} from 'antd'

const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState('1')
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filterBy, setFitterBy] = useState('')
  const [tabKeyValue, setTabKeyValue] = useState('PENDING')

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const sandboxStatus: any = useSelector<RootState>(
    ({dashboard}) => dashboard.sandboxStatus,
    shallowEqual
  )
    useEffect(()=>{
      callOrderData(tabKeyValue)
    },[sandboxStatus])
  const applyFilter = async () => {
    try {
      setIsLoading(true)

      await dispatch(order.actions.setOrderLoading(true))

      let orderData = await getOrderFilterData(
        0,
        10,
        tabKeyValue,
        searchText,
        filterBy,
        sandboxStatus
      )
      // }
      if (orderData.data) {
        await dispatch(order.actions.setOrderLoading(false))
        // once order data set then loading will automatically off using saga middleware
        await dispatch(order.actions.setOrderData(orderData.data))
        await dispatch(order.actions.setOrderDataTotal(orderData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setIsLoading(false)
    }
  }

  const callOrderData = async (status: string) => {
    try {
      if (filterBy && searchText) {
        applyFilter()
        return
      }
      setIsLoading(true)

      let orderData
      // if (user.role === 'admin') {
      //   orderData = await getAdminOrderData(0, 10)
      // } else {

      orderData = await getOrderData(0, 10, status,sandboxStatus)
      // }
      if (orderData.data) {
        await dispatch(order.actions.setOrderLoading(true))
        // once order data set then loading will automatically off using saga middleware
        await dispatch(order.actions.setOrderData(orderData.data))
        await dispatch(order.actions.setOrderDataTotal(orderData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeTab = (key: string) => {
    console.log(key, typeof key, 'key')
    setTabKey(key)

    if (key === '4') {
      setTabKeyValue('CANCELED')
    } else if (key === '3') {
      setTabKeyValue('DELIVERED')
    } else if (key === '2') {
      setTabKeyValue('SHIPPED')
    } else {
      setTabKeyValue('PENDING')
    }
  }

  useEffect(() => {
    if (tabKeyValue) {
      callOrderData(tabKeyValue)
    }
  }, [tabKeyValue])

  useEffect(() => {
    callOrderData('PENDING')
  }, [])

  return (
    <Spin spinning={isLoading} tip='Loading...'>
      <div className='card-container'>
        <div className='col-md-6 col-lg-6 col-sm-12' style={{display: 'flex', gap: '1rem'}}>
          <div style={{width: '100%'}}>
            <Select
              id='filter_type'
              name='filter_type'
              options={[
                {
                  label: 'ORDER CODE',
                  value: 'ORDER_CODE',
                },
                {
                  label: 'CERTIFICATE NO',
                  value: 'CERTIFICATE_NO',
                },
                {
                  label: 'VENDOR NAME',
                  value: 'VENDOR_NAME',
                },
                {
                  label: 'USER NAME',
                  value: 'USER_NAME',
                },
                {
                  label: 'STOCK ID',
                  value: 'STOCK_ID',
                },
              ]}
              placeholder='Apply filter'
              isSearchable={false}
              isClearable={true}
              onChange={(option: any) => setFitterBy(option?.value)}
              maxMenuHeight={200}
            />
          </div>
          <input
            id='input_type'
            name='input_type'
            placeholder='Enter value'
            type='text'
            className={`form-control`}
            autoComplete='off'
            onChange={(event) => setSearchText(event.target.value)}
            onBlur={(event) => console.log('Blurred:', event.target.value)}
          />
          <Button
            className='mb-5 rounded'
            style={{width: '100px'}}
            type='primary'
            onClick={() => {
              // this.generateCsv()
              applyFilter()
            }}
          >
            Apply filter
          </Button>
        </div>
        <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
          <TabPane tab='Pending' key='1'>
            <ListOrderData tabkey='PENDING' />
          </TabPane>
          {(user.role == 'super_admin' || user.role == 'sub_admin') && (
            <TabPane tab='Shipped' key='2'>
              <ListOrderData tabkey='SHIPPED' />
            </TabPane>
          )}
          {(user.role == 'super_admin' || user.role == 'sub_admin') && (
            <TabPane tab='Completed' key='3'>
              <ListOrderData tabkey='DELIVERED' />
            </TabPane>
          )}
          {(user.role == 'super_admin' || user.role == 'sub_admin') && (
            <TabPane tab='Cancelled' key='4'>
              <ListOrderData tabkey='CANCELED' />
            </TabPane>
          )}
          {/* {(user.role == 'super_admin' || user.role == 'sub_admin') && (
            <TabPane tab='Rejected' key='3'>
              <ListOrderData tabkey='CANCELED' />
            </TabPane>
          )} */}
        </Tabs>
      </div>
    </Spin>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/order/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/order/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.ORDER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
