/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getUsersData} from '../redux/UsersCRUD'
import {useDispatch} from 'react-redux'
import * as users from '../redux/UsersRedux'
import ListUsersData from './ListUsersData'
import {Tabs} from 'antd'
const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 10,
  })
  const [tabKey, setTabKey] = useState('Individual')

  const callUsersData = async () => {
    try {
      await dispatch(users.actions.setUsersLoading(true))

      let usersData = await getUsersData(searchPagination.skip, searchPagination.limit, tabKey)
      if (usersData.data) {
        // once users data set then loading will automatically off using saga middleware

        await dispatch(users.actions.setUsersData(usersData.data))
        await dispatch(users.actions.setUsersDataTotal(usersData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await dispatch(users.actions.setUsersLoading(false))
    }
  }

  useEffect(() => {
    callUsersData()
  }, [tabKey])

  return (
    <div>
      {/* <ListUsersData /> */}
      <Tabs
        defaultActiveKey='session'
        onChange={(key: string) => {
          console.log({key})
          setTabKey(key)
        }}
        type='card'
      >
        <TabPane tab='Individual' key='Individual'>
          {tabKey === 'Individual' && <ListUsersData type='Individual' />}
        </TabPane>
        <TabPane tab='Retailer' key='Retailer'>
          {tabKey === 'Retailer' && <ListUsersData type='Retailer' />}
        </TabPane>
      </Tabs>
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/users/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/users/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.CLIENT'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
