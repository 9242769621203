import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
// import {verifyKycStatus} from '../redux/KycCRUD'

interface IRenderFormikForm {
  values: IKycFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IKycFormValues {
  reject_reason: string
}

interface IKycProps {
  data?: any
  kycRejectedCloseModal: any
  handleCancel: any
  handleSubmit?: any
}

export class RejectReasonForm extends React.Component<IKycProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IKycFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.reject_reason) {
      errors.reject_reason = 'Reason is required'
    }

    console.log('Validating errors -- ', errors)
    return errors
  }

  //   public handleSubmit = async (values: IKycFormValues, action: any) => {
  //     try {
  //       let kycValues = this.props.data
  //       kycValues.reject_reason = values.reject_reason
  //     //   let kycData = await verifyKycStatus(kycValues)
  //     //   if (kycData && kycData.status == 200) {
  //     //     Toaster({
  //     //       type: 'success',
  //     //       title: 'KYC',
  //     //       description: 'KYC Status Changed Successfully',
  //     //     })
  //     //   }
  //     } catch (e) {
  //       console.log(e, '!!!!error in api call!!!!')
  //       Toaster({
  //         type: 'error',
  //         title: 'KYC',
  //         description: 'Something Went Wrong',
  //       })
  //       throw e
  //     } finally {
  //       this.props.kycRejectedCloseModal()
  //       this.setState({
  //         visible: false,
  //       })
  //     }
  //   }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {data, handleSubmit} = this.props

    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  reject_reason: '',
                }}
                validate={this.validateForm}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-12'>
                          <InputText
                            input={{
                              value: values.reject_reason,
                              id: 'reject_reason',
                              name: 'reject_reason',
                            }}
                            placeholder='Enter Reason For Rejection'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('reject_reason', value)
                            }}
                            onBlur={handleBlur}
                            label='Reason For Rejection'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='custom-card-footer'>
                        <div className='float-right'>
                          <InputButtonComponent onClick={handleSubmit}>Submit</InputButtonComponent>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
