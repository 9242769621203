/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getClientOfferData} from '../redux/ClientOfferCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as clientoffer from '../redux/ClientOfferRedux'
import ListClientOfferData from './ListClientOfferData'
import {Tabs} from 'antd'
import {RootState} from '../../../../setup'
import {Spin} from 'antd'
import {useLocation} from 'react-router-dom'

const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState('1')
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const callClientOfferData = async (status: string) => {
    try {
      setIsLoading(true)
      let stockOfferData
      if (location.pathname.includes('/vendor/list')) {
        stockOfferData = await getClientOfferData(
          0,
          10,
          status,
          status == 'REVISED' ? true : false,
          true
        )
      } else {
        stockOfferData = await getClientOfferData(0, 10, status, status == 'REVISED' ? true : false)
      }
      // }
      if (stockOfferData.data) {
        await dispatch(clientoffer.actions.setClientOfferLoading(true))
        // once clientoffer data set then loading will automatically off using saga middleware
        await dispatch(clientoffer.actions.setClientOfferData(stockOfferData.data))
        await dispatch(clientoffer.actions.setClientOfferDataTotal(stockOfferData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeTab = (key: string) => {
    setTabKey(key)
    if (key === '4') {
      callClientOfferData('REVISED')
    } else if (key === '3') {
      callClientOfferData('REJECTED')
    } else if (key === '2') {
      callClientOfferData('ACCEPTED')
    } else {
      callClientOfferData('PENDING')
    }
  }

  useEffect(() => {
    callClientOfferData('PENDING')
  }, [])

  return (
    <Spin spinning={isLoading} tip='Loading...'>
      <div className='card-container'>
        <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
          <TabPane tab='Pending' key='1'>
            <ListClientOfferData tabkey='PENDING' pathname={location.pathname} />
          </TabPane>
          <TabPane tab='In Progress' key='4'>
            <ListClientOfferData tabkey='REVISED' pathname={location.pathname} />
          </TabPane>
          <TabPane tab='Accepted' key='2'>
            <ListClientOfferData tabkey='ACCEPTED' pathname={location.pathname} />
          </TabPane>
          <TabPane tab='Rejected' key='3'>
            <ListClientOfferData tabkey='REJECTED' pathname={location.pathname} />
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()
  const location = useLocation()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/clientoffer/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/clientoffer/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.CLIENTOFFER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
