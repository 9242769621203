/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as returnorder from '../redux/ReturnOrderRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {getReturnOrderData, returnOrderActionApi} from '../redux/ReturnOrderCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import moment from 'moment'
import {Tag} from 'antd'
import {returnPriceInThousandFormatWithCurrencySign, returnStockId} from '../../../comman/helper'
import {CheckOutlined, CloseOutlined, EyeOutlined} from '@ant-design/icons'
import {ChangeReturnOrderStatusForm} from './ChangeReturnOrderStatusForm'
import {RetournOrderRejectReason} from './RetournOrderRejectReason'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  tabkey: any
  returnOrderData: any
  returnOrderDataTotal: number
  returnOrderLoading: boolean
  setListReturnOrderData: (returnOrderData: any) => void
  setListReturnOrderDataTotal: (returnOrderDataTotal: number) => void
  setReturnOrderLoading: (returnOrderLoading: boolean) => void
  history?: History<LocationState>
  loginUser?: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  orderSelectedData: any
  dataTableSelectedRowKeys: any
  selectedRowsData: any
  changeStatusModalState: any
  reasonModalState: any
}

type PathParamsType = {
  param1: string
}
class ListReturnOrderData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      orderSelectedData: null,
      changeStatusModalState: null,
      dataTableSelectedRowKeys: [],
      selectedRowsData: [],
      reasonModalState: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListReturnOrderData(pagination)
    )
  }

  public getListReturnOrderData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listReturnOrderSearchData(start, end)
        } else {
          this.listReturnOrderData(start, end)
        }
      }
    )
  }

  public listReturnOrderData = async (start: number, end: any) => {
    const {setReturnOrderLoading} = this.props
    try {
      const {setListReturnOrderData, setListReturnOrderDataTotal, tabkey, loginUser} = this.props
      await setReturnOrderLoading(true)
      let returnOrderData = await getReturnOrderData(
        start,
        end,
        tabkey,
        loginUser.role ? loginUser.role : ''
      )
      if (returnOrderData.data) {
        await setListReturnOrderData(returnOrderData.data)
        await setListReturnOrderDataTotal(returnOrderData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setReturnOrderLoading(false)
    }
  }

  public listReturnOrderSearchData = async (start: number, end: any) => {
    const {setReturnOrderLoading} = this.props
    try {
      const {setListReturnOrderData, setListReturnOrderDataTotal, tabkey, loginUser} = this.props
      const {searchText} = this.state

      await setReturnOrderLoading(true)
      let returnOrderData = await getReturnOrderData(
        start,
        end,
        tabkey,
        loginUser.role ? loginUser.role : ''
      )
      if (returnOrderData.data) {
        await setListReturnOrderData(returnOrderData.data)
        await setListReturnOrderDataTotal(returnOrderData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setReturnOrderLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listReturnOrderSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public showPreviewModal = async (item: any) => {
    this.setState({
      orderSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      orderSelectedData: null,
    })
    this.listReturnOrderData(this.state.startIndex, this.state.limitNumber)
  }

  public showStatusModal = async (item: any) => {
    this.setState({
      changeStatusModalState: item,
    })
  }

  public closeStatusModal = async () => {
    this.setState({
      changeStatusModalState: null,
    })
    this.setState({
      dataTableSelectedRowKeys: [],
      selectedRowsData: [],
    })
    this.listReturnOrderData(this.state.startIndex, this.state.limitNumber)
  }

  public navigateToPreviewOrderPage = async (item: any) => {
    const {history} = this.props
    history.push(`${process.env.PUBLIC_URL}/returnorder/preview/` + item.id)
  }

  public returnOrderAction = async (item: any, status: boolean) => {
    const {searchText, startIndex, limitNumber} = this.state
    const {setReturnOrderLoading} = this.props
    try {
      let reqObj: any = {}
      reqObj.return_order_id = item.id ? item.id : ''
      reqObj.is_accepted = status ? true : false

      await setReturnOrderLoading(true)

      let returnData = await returnOrderActionApi(reqObj)

      if (returnData && returnData.status == 200) {
        Toaster({
          type: 'success',
          title: 'RETURN ORDER',
          description: 'Action Taken SuccessFully',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'RETURN ORDER',
          description: 'Something Went Wrong',
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.listReturnOrderData(this.state.startIndex, this.state.limitNumber)

      await setReturnOrderLoading(true)
    }
  }

  public showReasonModalModal = (item: any) => {
    this.setState({
      reasonModalState: item,
    })
  }

  public closeReasonModal = async () => {
    const {searchText, startIndex, limitNumber} = this.state
    const {setReturnOrderLoading} = this.props
    this.setState({
      reasonModalState: null,
    })
    if (searchText && searchText !== '') {
      this.listReturnOrderSearchData(this.state.startIndex, this.state.limitNumber)
    } else {
      this.listReturnOrderData(this.state.startIndex, this.state.limitNumber)
    }
    await setReturnOrderLoading(true)
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      // title: 'Stock Id',
      title: 'STOCK ID',
      key: 'Stock Id',
      width: 120,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        const queryParams = item.vendor_id ? `?role=vendor&id=${item.vendor_id}` : ''
        return (
          <div>
            <a
              href={`/inventory/preview/${
                item.stock.stock_id ? item.stock.stock_id : ''
              }${queryParams}`}
              target='_blank'
            >
              <strong>
                {item.stock && item.stock.stock_id ? returnStockId(item.stock.stock_id) : '-'}
              </strong>
            </a>
          </div>
        )
      },
    },
    {
      title: 'Order Code',
      key: 'order_code',
      width: 110,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.order && item.order.order_code ? returnStockId(item.order.order_code) : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      width: 80,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.shape ? returnStockId(item.stock.shape) : '-'}</div>
      },
    },
    {
      // title: 'Weight',
      title: 'WEIGHT',
      key: 'weight',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.weight ? item.stock.weight : '-'}</div>
      },
    },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      // ellipsis: true,
      // width: 40,
      width: 65,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.color ? returnStockId(item.stock.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.clarity ? returnStockId(item.stock.clarity) : '-'}</div>
        )
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      // width: 40,
      width: 45,
      render: (text: any, item: any, index: number) => {
        return <div>{item.cut ? returnStockId(item.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      width: 120,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.polish ? returnStockId(item.stock.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      width: 120,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.symmetry ? returnStockId(item.stock.symmetry) : '-'}</div>
        )
      },
    },

    {
      // title: 'Fluorescence Intensity',
      title: 'FL',
      // ellipsis: true,
      key: 'fluorescence_intensity',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.stock && item.stock.fluorescence_intensity
              ? returnStockId(item.stock.fluorescence_intensity)
              : '-'}
          </div>
        )
      },
    },
    {
      title: 'Date',
      key: 'createdAt',
      width: 100,
      sorter: (a: any, b: any) => {
        const dateA: any = new Date(a.createdAt)
        const dateB: any = new Date(b.createdAt)
        return dateA - dateB
      },
      render: (text: any, item: any, index: number) => {
        return <div>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY') : '-'}</div>
      },
    },
    {
      title: 'Return Reason',
      key: 'reason',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return (
          <Tooltip placement='bottom' title={item && item.reason ? item.reason : '-'}>
            <div className='d-inline-block text-truncate' style={{maxWidth: '100px'}}>
              {' '}
              {item && item.reason ? item.reason : '-'}
            </div>
          </Tooltip>
        )
      },
    },
  ]

  //   rowSelection = {
  //     onChange: async (selectedRowKeys: any, selectedRows: any, selected: any) => {
  //       this.setState({dataTableSelectedRowKeys: selectedRowKeys, selectedRowsData: selectedRows})
  //     },
  //   }

  public render() {
    const {returnOrderData, returnOrderDataTotal, returnOrderLoading, loginUser, tabkey} =
      this.props
    const {
      searchText,
      orderSelectedData,
      selectedRowsData,
      dataTableSelectedRowKeys,
      changeStatusModalState,
      reasonModalState,
    } = this.state
    const {role} = loginUser

    const columnExists = (key: any) => {
      return this.columns.some((column) => column.key === key)
    }

    if (loginUser && loginUser.role !== 'vendor' && !columnExists('vendor_details')) {
      this.columns.unshift({
        title: 'VENDOR',
        key: 'vendor_details',
        width: 150,
        render: (text, item, index) => {
          return (
            <div>
              {item.vendor
                ? item.vendor.first_name && item.vendor.last_name
                  ? `${item.vendor.first_name} ${item.vendor.last_name}`
                  : item.vendor.first_name
                  ? item.vendor.first_name
                  : '-'
                : '-'}
            </div>
          )
        },
      })
    }

    if (loginUser && loginUser.role !== 'vendor' && !columnExists('received_amount')) {
      this.columns.push({
        title: 'Received Amount',
        key: 'received_amount',
        width: 100,
        render: (text: any, item: any, index: number) => {
          return (
            <div>
              {item && item.received_amount
                ? returnPriceInThousandFormatWithCurrencySign(item.received_amount.toFixed(2))
                : '0'}
            </div>
          )
        },
      })
    }

    if (!columnExists('refund_amount')) {
      this.columns.push({
        title: 'Refund Amount',
        key: 'refund_amount',
        width: 100,
        render: (text: any, item: any, index: number) => {
          return (
            <div>
              {loginUser && loginUser.role == 'vendor'
                ? item && item.vendor_refund_amount
                  ? returnPriceInThousandFormatWithCurrencySign(
                      item.vendor_refund_amount.toFixed(2)
                    )
                  : '0'
                : item && item.refund_amount
                ? returnPriceInThousandFormatWithCurrencySign(item.refund_amount.toFixed(2))
                : '0'}
            </div>
          )
        },
      })
    }

    if (
      loginUser &&
      loginUser.role !== 'vendor' &&
      tabkey == 'Vendor' &&
      !columnExists('vendor_refund_amount')
    ) {
      this.columns.push({
        title: 'Vendor Refund Amount',
        key: 'vendor_refund_amount',
        width: 100,
        render: (text: any, item: any, index: number) => {
          return (
            <div>
              {item && item.vendor_refund_amount
                ? returnPriceInThousandFormatWithCurrencySign(item.vendor_refund_amount.toFixed(2))
                : '0'}
            </div>
          )
        },
      })
    }

    if (tabkey == 'Vendor' && !columnExists('status')) {
      this.columns.push({
        // title: 'Status',
        title: 'SHIPING STATUS',
        key: 'status',
        width: 100,
        // fixed: window.innerWidth < 768 ? false : true,
        render: (text: any, item: any, index: number) => {
          return (
            <div>
              {' '}
              {item.status ? (
                <Tag
                  color={
                    config.statusTagColor[item.status]
                      ? config.statusTagColor[item.status]
                      : 'default'
                  }
                >
                  {item.status}
                </Tag>
              ) : (
                '-'
              )}
            </div>
          )
        },
      })
    }

    if (role == 'vendor' && tabkey == 'Vendor' && !columnExists('is_accepted')) {
      this.columns.push({
        // title: 'Status',
        title: 'REQ. ACCEPTED',
        key: 'is_accepted',
        width: 100,
        render: (text: any, item: any, index: number) => {
          return (
            <div>
              {' '}
              {item.is_accepted ? <Tag color={'success'}>YES</Tag> : <Tag color={'red'}>NO</Tag>}
            </div>
          )
        },
      })
    }

    if ((!columnExists('reject_reason') && tabkey == 'Rejected') || tabkey == 'Vendor') {
      this.columns.push({
        title: 'Reject Reason',
        key: 'reject_reason',
        width: 100,
        render: (text: any, item: any, index: number) => {
          return (
            <Tooltip
              placement='bottom'
              title={item && item.reject_reason ? item.reject_reason : '-'}
            >
              <div className='d-inline-block text-truncate' style={{maxWidth: '100px'}}>
                {' '}
                {item && item.reject_reason ? item.reject_reason : '-'}
              </div>
            </Tooltip>
          )
        },
      })
    }

    if (
      loginUser &&
      loginUser.role !== 'vendor' &&
      !columnExists('vendor_action') &&
      tabkey == 'Vendor'
    ) {
      this.columns.push({
        title: 'Actions',
        key: 'vendor_action',
        width: 100,
        render: (text: any, item: any, index: number) => {
          return (
            <div style={{display: 'inline-block', width: '100%'}}>
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Change Status'>
                  <Button
                    shape='circle'
                    icon={<EyeOutlined />}
                    onClick={() => {
                      // this.showPreviewModal(item)
                      this.showStatusModal(item)
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          )
        },
      })
    }

    if (
      loginUser &&
      loginUser.role !== 'vendor' &&
      !columnExists('action') &&
      tabkey == 'Pending'
    ) {
      this.columns.push({
        title: 'Actions',
        key: 'action',
        width: 125,
        render: (text: any, item: any, index: number) => {
          return (
            <>
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Reject Request'>
                  {/* <Popconfirm
                    onConfirm={() => {
                      //call delete client api
                      this.returnOrderAction(item, false)
                    }}
                    title='Are you sure you want to reject Return Request?'
                  > */}
                  <Button
                    shape='circle'
                    icon={<CloseOutlined />}
                    onClick={() => {
                      this.showReasonModalModal(item)
                    }}
                  />
                  {/* </Popconfirm> */}
                </Tooltip>
              </div>
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Approve Request'>
                  <Popconfirm
                    onConfirm={() => {
                      //call delete client api
                      this.returnOrderAction(item, true)
                    }}
                    title='Are you sure you want to approve Return Request?'
                  >
                    <Button shape='circle' icon={<CheckOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            </>
          )
        },
      })
    }

    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div style={{overflowX: 'auto'}}>
              <ListTable
                rowKey={(record: {id: any}) => record.id}
                onChange={this.handleTableChange}
                onPaginationChange={(e: any) => {
                  if (e - 1) {
                    this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                    return
                  }

                  this.counter = 0
                  this.tempCounter = 0
                }}
                column={this.columns}
                paginationConfig={{
                  total: returnOrderDataTotal,
                  showSizeChanger: true,
                  pageSize: this.state.pagination.pageSize,
                  current: this.state.pagination.current,
                }}
                isLoading={returnOrderLoading}
                dataSource={returnOrderData}
                counter={this.counter}
                tempCounter={this.tempCounter}
                isScroll={true}
                scrollWidth={1600}
                // rowSelection={
                //   (loginUser?.role !== 'super_admin' || loginUser?.role !== 'sub_admin')
                //     ? {
                //         type: 'checkbox',
                //         ...this.rowSelection,
                //         selectedRowKeys: dataTableSelectedRowKeys ? dataTableSelectedRowKeys : [],
                //       }
                //     : undefined
                // }
              />
              <div>
                {/* <CommanModal
                    show={orderSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Preview Order'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {orderSelectedData && (
                        <PreviewOrderForm
                          data={orderSelectedData}
                          loginUserData={loginUser}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal> */}
                <CommanModal
                  show={changeStatusModalState ? true : false}
                  handleClose={() => {
                    this.closeStatusModal()
                  }}
                  title={'Return Order Status'}
                  submitText={'Save Changes'}
                >
                  <div>
                    {changeStatusModalState && (
                      <ChangeReturnOrderStatusForm
                        data={changeStatusModalState}
                        handleCancel={() => {
                          this.closeStatusModal()
                        }}
                      />
                    )}
                  </div>
                </CommanModal>
                <CommanModal
                  show={reasonModalState ? true : false}
                  handleClose={() => {
                    this.closeReasonModal()
                  }}
                  title={'Reject Reason'}
                  submitText={'Save Changes'}
                  isScrollable={true}
                >
                  <div>
                    {reasonModalState && (
                      <RetournOrderRejectReason
                        data={reasonModalState}
                        handleCancel={() => {
                          this.closeReasonModal()
                        }}
                      />
                    )}
                  </div>
                </CommanModal>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  returnOrderData: state.returnorder.returnOrderData,
  returnOrderDataTotal: state.returnorder.returnOrderDataTotal,
  returnOrderLoading: state.returnorder.returnOrderLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListReturnOrderData: (returnOrderData: any) => {
      dispatch(returnorder.actions.setReturnOrderData(returnOrderData))
    },
    setListReturnOrderDataTotal: (returnOrderDataTotal: number) => {
      dispatch(returnorder.actions.setReturnOrderDataTotal(returnOrderDataTotal))
    },
    setReturnOrderLoading: (returnOrderLoading: boolean) => {
      dispatch(returnorder.actions.setReturnOrderLoading(returnOrderLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListReturnOrderData))
