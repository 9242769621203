/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getBuyRequestData, getBuyRequestFilterData} from '../redux/BuyRequestCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as buyRequest from '../redux/BuyRequestRedux'
import ListBuyRequestData from './ListBuyRequestData'
import {Tabs} from 'antd'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {Spin} from 'antd'
import Select from 'react-select'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {Button} from 'antd'
const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState('1')
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filterBy, setFitterBy] = useState('')
  const [tabKeyValue, setTabKeyValue] = useState('PENDING')
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const sandboxStatus: any = useSelector<RootState>(
    ({dashboard}) => dashboard.sandboxStatus,
    shallowEqual
  )
  useEffect(()=>{
    callBuyRequestData(tabKeyValue)
  },[sandboxStatus])
  const {role, permission} = user
  const applyFilter = async () => {
    try {
      // console.log(status, 'call')
      if (!filterBy && !searchText) {
        return
      }
      setIsLoading(true)
      await dispatch(buyRequest.actions.setBuyRequestLoading(true))

      let buyRequestData = await getBuyRequestFilterData(
        0,
        10,
        tabKeyValue,
        searchText,
        filterBy,
        sandboxStatus
      )
      if (buyRequestData.data) {
        await dispatch(buyRequest.actions.setBuyRequestLoading(false))
        // once buyRequest data set then loading will automatically off using saga middleware
        await dispatch(buyRequest.actions.setBuyRequestData(buyRequestData.data))
        await dispatch(buyRequest.actions.setBuyRequestDataTotal(buyRequestData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setIsLoading(false)
      await dispatch(buyRequest.actions.setBuyRequestLoading(false))
    }
  }

  const callBuyRequestData = async (status = '') => {
    try {
      console.log(status, 'call')
      if (filterBy && searchText) {
        applyFilter()
        return
      }
      setIsLoading(true)
      let buyRequestData = await getBuyRequestData(0, 10, status, sandboxStatus)
      if (buyRequestData.data) {
        await dispatch(buyRequest.actions.setBuyRequestLoading(true))
        // once buyRequest data set then loading will automatically off using saga middleware
        await dispatch(buyRequest.actions.setBuyRequestData(buyRequestData.data))
        await dispatch(buyRequest.actions.setBuyRequestDataTotal(buyRequestData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeTab = (key: string) => {
    console.log(key, typeof key, 'key')
    setTabKey(key)
    if (key === '3') {
      setTabKeyValue('canceled,auto-canceled')
    } else if (key === '2') {
      setTabKeyValue('updated')
    } else if (key === '4') {
      setTabKeyValue('accepted')
    } else {
      setTabKeyValue('pending')
    }
  }

  useEffect(() => {
    if (tabKeyValue) {
      callBuyRequestData(tabKeyValue)
    }
  }, [tabKeyValue])

  useEffect(() => {
    {
      callBuyRequestData('pending')
    }
  }, [])

  return (
    // <div>
    //   <ListBuyRequestData />
    // </div>
    <div className='card p-5'>
      <Spin spinning={isLoading} tip='Loading...'>
        <div className='card-container'>
          <div className='col-md-6 col-lg-6 col-sm-12' style={{display: 'flex', gap: '1rem'}}>
            <div style={{width: '100%'}}>
              <Select
                id='filter_type'
                name='filter_type'
                options={[
                  {
                    label: 'ORDER CODE',
                    value: 'ORDER_CODE',
                  },
                  {
                    label: 'CERTIFICATE NO',
                    value: 'CERTIFICATE_NO',
                  },
                  {
                    label: 'VENDOR NAME',
                    value: 'VENDOR_NAME',
                  },
                  {
                    label: 'USER NAME',
                    value: 'USER_NAME',
                  },
                  {
                    label: 'STOCK ID',
                    value: 'STOCK_ID',
                  },
                ]}
                placeholder='Apply filter'
                isSearchable={false}
                isClearable={true}
                onChange={(option: any) => setFitterBy(option?.value)}
                maxMenuHeight={200}
              />
            </div>
            <input
              id='input_type'
              name='input_type'
              placeholder='Enter value'
              type='text'
              className={`form-control`}
              autoComplete='off'
              onChange={(event) => setSearchText(event.target.value)}
              onBlur={(event) => console.log('Blurred:', event.target.value)}
            />
            <Button
              className={`mb-5 rounded`}
              style={{width: '100px'}}
              // disabled={!searchText && !filterBy}
              type='primary'
              onClick={() => {
                // this.generateCsv()
                applyFilter()
              }}
            >
              Apply filter
            </Button>
          </div>
          <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
            <TabPane tab='Pending' key='1'>
              <ListBuyRequestData tabkey='pending' />
            </TabPane>
            {(user.role == 'super_admin' || user.role == 'sub_admin') && (
              <TabPane tab='In Progress' key='2'>
                <ListBuyRequestData tabkey='updated' />
              </TabPane>
            )}
            {(user.role == 'super_admin' || user.role == 'sub_admin') && (
              <TabPane tab='Rejected' key='3'>
                <ListBuyRequestData tabkey='canceled,auto-canceled' />
              </TabPane>
            )}
            {(user.role == 'super_admin' || user.role == 'sub_admin') && (
              <TabPane tab='Completed' key='4'>
                <ListBuyRequestData tabkey='accepted' />
              </TabPane>
            )}
          </Tabs>
        </div>
      </Spin>
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/buyRequest/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/buyRequest/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.BUYREQUEST'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
