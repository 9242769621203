/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {JewelleryShowcaseVideosPage} from '../../component/jewelleryShowcaseVideos/JewelleryShowcaseVideosPage'

const JewelleryShowcaseVideoOrderWrapper: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  useEffect(() => {}, [])

  return (
    <div>
      <JewelleryShowcaseVideosPage />
    </div>
  )
}

export {JewelleryShowcaseVideoOrderWrapper}
