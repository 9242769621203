import React, {Component} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {clientOfferTrailsApi} from '../redux/ClientOfferCRUD'
import {useParams} from 'react-router-dom'
import moment from 'moment'

class ListTrailsData extends Component {
  state = {
    offerTrails: [], // Initial state
  }
  public callInitialData = async () => {
    const pathParts = window.location.pathname.split('/')
    const id = pathParts[pathParts.length - 1]
    let offerTrails: any = await clientOfferTrailsApi(id)
    this.setState({offerTrails: offerTrails.data})
  }

  componentDidMount() {
    this.callInitialData()
  }
  vendorsDataCounter = 0
  vendorsDataTempCounter = 0
  public vendorsDataColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.vendorsDataTempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Last Action Type',
      key: 'last_action_type',
      render: (text: any, item: any, index: number) => {
        console.log({item})
        return (
          <div>
            <strong>{item?.last_action_type || '-'}</strong>
          </div>
        )
      },
    },
    
    {
      title: 'Client Offer Price Per Caret ($/CT)',
      key: 'offer_price_per_caret',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.offer_price_per_caret
                ? `$${parseFloat(item.offer_price_per_caret).toFixed(2)}`
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Client Offer Price',
      key: 'offer_price',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.offer_price ? `$${parseFloat(item.offer_price).toFixed(2)}` : '-'}
            </strong>
          </div>
        )
      },
    },
   
    {
      title: 'Vendor Offer Price Per Caret ($/CT)',
      key: 'updated_offer_price_per_caret',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.updated_offer_price_per_caret
                ? `$${parseFloat(item.updated_offer_price_per_caret).toFixed(2)}`
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Vendor Offer Price',
      key: 'updated_offer_price',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.updated_offer_price
                ? `$${parseFloat(item.updated_offer_price).toFixed(2)}`
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Client Base Price',
      key: 'base_price',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item?.base_price ? `$${parseFloat(item.base_price).toFixed(2)}` : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Base Price Vendor',
      key: 'base_price_vendor',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.base_price_vendor ? `$${parseFloat(item.base_price_vendor).toFixed(2)}` : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Offer Submitted Updated At',
      key: 'updatedAt',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.updatedAt ? moment(item.updatedAt).format('MMM DD, YYYY, hh:mm A') : '-'}
            </strong>
          </div>
        )
      },
    },
  ]

  render() {
    return (
      <div className='pt-4' style={{marginBottom: '30px'}}>
        <div className='col-lg-12' style={{padding: 0}}>
          <div style={{fontSize: '20px', fontWeight: 500}}>Offer Trails Details :</div>
          <>
            <div style={{overflowX: 'auto'}}>
              <div className='card-toolbar d-flex justify-content-end mb-4'>
                <div className='ms-3'>
                  {/* <InputButtonComponent
                          onClick={() => {
                            this.generateCsv()
                          }}
                        >
                          Export Vendor Stock Details
                        </InputButtonComponent> */}
                </div>
              </div>
              <ListTable
                rowKey={(record: {_id: any}) => record._id}
                onChange={() => {}}
                onPaginationChange={(e: any) => {}}
                column={this.vendorsDataColumns}
                paginationConfig={{
                  total: this.state.offerTrails.length,
                  showSizeChanger: false,
                  pageSize: 10000,
                  current: 1,
                }}
                isLoading={false}
                dataSource={this.state.offerTrails}
                counter={this.vendorsDataCounter}
                tempCounter={this.vendorsDataTempCounter}
                isScroll={true}
                scrollWidth={1600}
              />
            </div>
          </>
        </div>
      </div>
    )
  }
}

export default ListTrailsData
