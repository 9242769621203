import React from 'react'
import axios from 'axios'
import {OrderModal} from '../models/OrderModal'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_ORDER_URL = `${API_URL}api/v1/auth/admin/unified-order`
export const PREVIEW_ORDER_DETAILS = `${API_URL}api/v1/auth/admin/order-details`
export const EDIT_ORDER_STATUS_URL = `${API_URL}api/v1/auth/admin/order-status`
export const GET_VENDOR_ORDER_URL = `${API_URL}api/v1/auth/admin/vendor-order`
export const UPDATE_ORDER_INVOICE_URL = `${API_URL}api/v1/auth/admin/update-invoice`
export const CANCEL_ORDER_URL = `${API_URL}api/v1/auth/admin/unified-cancel-order`
export const CREATE_NEW_RETURN_ORDER_URL = `${API_URL}api/v1/auth/admin/return-order`

interface ListType {
  count?: any
  data: any
}

// Get Order Data
export async function getOrderData(
  startIndex: number,
  limitNumber: number,
  status?: string,
  // isVendorOffer?: any,
  isSandbox?:any
) {
  try {
    let getOrderAPIPath = ''
    if (status !== undefined && status !== '') {
      getOrderAPIPath = `${GET_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&order_status=${status}&unified_order_type=DIAMONDS&is_sandbox=${isSandbox}`
    } else {
      getOrderAPIPath = `${GET_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&order_status=PENDING&unified_order_type=DIAMONDS&is_sandbox=${isSandbox}`
    }

    let orderData: ListType = await axios.get(getOrderAPIPath)
    // let orderData: ListType = await axios.get(`${GET_ORDER_URL}`)
    return orderData
  } catch (e) {
    throw e
  }
}

export async function getOrderStockDetails(req_id: string) {
  try {
    let orderDetailsData: ListType = await axios.get(`${PREVIEW_ORDER_DETAILS}?id=${req_id}`)
    return orderDetailsData
  } catch (e) {
    throw e
  }
}

export async function changeOrderStausDataApi(values: any) {
  let editUserData = await axios.put(`${EDIT_ORDER_STATUS_URL}`, {
    ...values,
  })
  return editUserData
}

export async function getVendorStockDataForOrder(order_id: string) {
  try {
    let orderData: ListType = await axios.get(`${GET_VENDOR_ORDER_URL}?order_id=${order_id}`)
    return orderData
  } catch (e) {
    throw e
  }
}

export async function invoiceOrderApi(values: any) {
  let updateInvoiceData = await axios.put(`${UPDATE_ORDER_INVOICE_URL}`, {
    ...values,
  })
  return updateInvoiceData
}

export async function getOrderDataForCsv(id?: any) {
  try {
    let vendorStockDetailsData = await axios.get(`${GET_VENDOR_ORDER_URL}?order_id=${id}`)
    return vendorStockDetailsData
  } catch (e) {
    throw e
  }
}

export async function cancelOrderDataApi(data: any) {
  try {
    let cancelledOrderData = await axios.put(`${CANCEL_ORDER_URL}`, {
      ...data,
    })
    return cancelledOrderData
  } catch (e) {
    console.log(e, 'error! in cancel Order Api')
  }
}

export async function exportAllOrderDataCsv(key: string,isSandbox?:any) {
  try {
    let csvData = await axios.get(`${GET_ORDER_URL}?unified_order_type=DIAMONDS&order_status=${key}&is_sandbox=${isSandbox}`)
    return csvData
  } catch (e) {
    console.log(e, 'error!!!!!')
  }
}

export async function createReturnOrderData(returnOrderObject: any) {
  try {
    console.log({returnOrderObject})
    let createNewReturnOrderData = await axios.post(`${CREATE_NEW_RETURN_ORDER_URL}`, {
      ...returnOrderObject,
    })
    return createNewReturnOrderData
  } catch (e) {
    throw e
  }
}

export async function getOrderFilterData(
  startIndex: number,
  limitNumber: number,
  status?: string,
  searchText?: string,
  filterBy?: string,
  isSandbox?:any
) {
  try {
    let getOrderAPIPath = ''
    if (status !== undefined && status !== '') {
      getOrderAPIPath = `${GET_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&order_status=${status}&unified_order_type=DIAMONDS&filter_keyword=${searchText}&filter_by=${filterBy}&is_sandbox=${isSandbox}`
    } else {
      getOrderAPIPath = `${GET_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&order_status=PENDING&unified_order_type=DIAMONDS&filter_keyword=${searchText}&filter_by=${filterBy}&is_sandbox=${isSandbox}`
    }

    let orderData: ListType = await axios.get(getOrderAPIPath)
    // let orderData: ListType = await axios.get(`${GET_ORDER_URL}`)
    return orderData
  } catch (e) {
    throw e
  }
}
