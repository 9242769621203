/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as users from '../redux/UsersRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {EyeOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {
  changeBlockToogleData,
  changeStatusUsersData,
  deleteUserData,
  editUserData,
  getSearchUsersData,
  getUsersData,
} from '../redux/UsersCRUD'
import {getAvailableCreditsData} from '../../kyc/redux/KycCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {EditUserForm} from './EditUserForm'
import ViewUsersDetails from './ViewUsersDetails'
import {Tag} from 'antd'
// import {EditUserForm} from './EditUserForm'
// import {IClientFormValues} from './CreateClientForm'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDollarSign} from '@fortawesome/free-solid-svg-icons'
import {Spin} from 'antd'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  usersData: any
  usersDataTotal: number
  usersLoading: boolean
  setListUsersData: (usersData: any) => void
  setListUsersDataTotal: (usersDataTotal: number) => void
  setUsersLoading: (usersLoading: boolean) => void
  history?: History<LocationState>
  type?: string
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  usersSelectedData: any
  viewUserSelectedData: any
  loaderForId: any
  mapAvailableCredits: any
}

type PathParamsType = {
  param1: string
}
class ListUsersData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      usersSelectedData: null,
      viewUserSelectedData: null,
      loaderForId: null,
      mapAvailableCredits: {},
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListUsersData(pagination)
    )
  }

  public getListUsersData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listUserSearchData(start, end)
        } else {
          this.listUserData(start, end)
        }
      }
    )
  }

  public listUserData = async (start: number, end: any) => {
    const {setUsersLoading} = this.props
    try {
      const {setListUsersData, setListUsersDataTotal} = this.props
      await setUsersLoading(true)
      let usersData = await getUsersData(start, end, this.props.type)
      if (usersData.data) {
        await setListUsersData(usersData.data)
        await setListUsersDataTotal(usersData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setUsersLoading(false)
    }
  }

  public listUserSearchData = async (start: number, end: any) => {
    const {setUsersLoading} = this.props
    try {
      const {setListUsersData, setListUsersDataTotal} = this.props
      const {searchText} = this.state

      await setUsersLoading(true)
      let usersData = await getSearchUsersData(start, end, searchText,this.props.type)
      if (usersData.data) {
        await setListUsersData(usersData.data)
        await setListUsersDataTotal(usersData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setUsersLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listUserSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateUser = () => {
    const {history} = this.props
    history.push('/users/create')
  }

  public deleteUser = async (item: any) => {
    const {setUsersLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setUsersLoading(true)
      let userData = await deleteUserData(item.id)

      if (userData && userData.status == 200) {
        Toaster({
          type: 'success',
          title: 'USERS',
          description: 'Deleted SuccessFully',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'USERS',
          description: 'Failed TO Delete User',
        })
      }
      if (searchText != null && searchText !== '') {
        this.listUserSearchData(startIndex, limitNumber)
        return
      }
      this.listUserData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setUsersLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setUsersLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setUsersLoading(true)
      await changeStatusUsersData(item.id, checked)

      Toaster({
        type: 'success',
        title: 'USERS',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listUserSearchData(startIndex, limitNumber)
        return
      }
      this.listUserData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setUsersLoading(false)
    }
  }

  public onBlockUser = async (item: any, checked: boolean) => {
    const {setUsersLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setUsersLoading(true)
      await changeBlockToogleData(item.id, checked)
      if (checked) {
        Toaster({
          type: 'success',
          title: 'USERS',
          description: 'Blocked successFully',
        })
      } else {
        Toaster({
          type: 'success',
          title: 'USERS',
          description: 'Unblocked successFully',
        })
      }
      if (searchText != null && searchText !== '') {
        this.listUserSearchData(startIndex, limitNumber)
        return
      }
      this.listUserData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
      Toaster({
        type: 'error',
        title: 'USERS',
        description: 'Something Went Wrong',
      })
    } finally {
      await setUsersLoading(false)
    }
  }

  public onEditSubmit = async (values: any) => {
    const {setUsersLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setUsersLoading(true)
      let editUser = await editUserData(values)

      if (editUser && editUser.status == 200) {
        Toaster({
          type: 'success',
          title: 'USER',
          description: 'Edited SuccessFully',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'USERS',
          description: 'Failed TO Edit User',
        })
      }

      if (searchText != null && searchText !== '') {
        this.listUserSearchData(startIndex, limitNumber)
        return
      }
      this.listUserData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setUsersLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      usersSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      usersSelectedData: null,
    })
  }

  public showViewModal = async (item: any) => {
    this.setState({
      viewUserSelectedData: item,
    })
  }

  public closeViewModal = async () => {
    this.setState({
      viewUserSelectedData: null,
    })
  }

  public checkAvailableCredits = async (id: any) => {
    try {
      this.setState({
        loaderForId: id,
      })
      let creditsData = await getAvailableCreditsData(id)

      if (creditsData) {
        this.setState((prevState: any) => ({
          mapAvailableCredits: {
            ...prevState.mapAvailableCredits,
            [id]: creditsData.data ? creditsData.data.toFixed(2) : 0,
          },
        }))
      }
    } catch (e) {
    } finally {
      this.setState({
        loaderForId: null,
      })
    }
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Full Name',
      key: 'Full Name',
      width: 125,
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.first_name && item.last_name
                ? `${item.first_name} ${item.last_name}`
                : item.first_name
                ? item.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    // {
    //   title: 'Credit Limit',
    //   key: 'credit_limit',
    //   width: 100,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.credit_limit ? item.credit_limit : '0'}</div>
    //   },
    // },
    {
      title: 'Email',
      key: 'email',
      width: 200,
      render: (text: any, item: any, index: number) => {
        return <div>{item.email ? item.email : '-'}</div>
      },
    },
    // {
    //   title: 'Username',
    //   key: 'username',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.username ? item.username : '-'}</div>
    //   },
    // },
    // {
    //   title: 'Company Name',
    //   key: 'company_name',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.company_name ? item.company_name : '-'}</div>
    //   },
    // },
    {
      title: 'Phone',
      key: 'phone',
      width: 125,
      render: (text: any, item: any, index: number) => {
        return <div>{item.phone ? item.phone : '-'}</div>
      },
    },
    {
      title: 'Verified',
      key: 'is_verified',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_verified ? (
              <Tag color={'success'}>{'Yes'}</Tag>
            ) : (
              <Tag color={'warning'}>{'No'}</Tag>
            )}
          </div>
        )
      },
    },
    {
      title: 'isBlocked',
      key: 'is_blocked',
      width: 85,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <Switch
              checked={item.is_blocked}
              style={{
                backgroundColor: item.is_blocked ? PRIMARY_COLOR : '#bababa',
              }}
              onChange={(checked: boolean) => {
                //call onChange Method with item
                this.onBlockUser(item, checked)
              }}
            />
          </div>
        )
      },
    },
    {
      title: 'isActive',
      key: 'isActive',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_active != null ? (
              <Switch
                checked={item.is_active}
                style={{
                  backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
                }}
                onChange={(checked: boolean) => {
                  //call onChange Method with item
                  this.onChangeStatus(item, checked)
                }}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      width: 200,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        const {mapAvailableCredits, loaderForId} = this.state
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            {/* <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div> */}
            {console.log(mapAvailableCredits, 'mapAvailableCredits')}
            {mapAvailableCredits &&
            (mapAvailableCredits[item.id] || mapAvailableCredits[item.id] === 0) ? (
              <div
                onClick={() => {
                  this.checkAvailableCredits(item.id)
                }}
                style={{float: 'left', marginRight: '5px', cursor: 'pointer'}}
              >
                {' '}
                {loaderForId && loaderForId == item.id ? (
                  <Spin style={{position: 'relative', top: '5px'}} spinning={loaderForId}></Spin>
                ) : (
                  <Tooltip placement='bottom' title='Revaluate Credit'>
                    <span
                      style={{position: 'relative', top: '5px', color: '#1ed83d', fontWeight: 500}}
                    >
                      ${mapAvailableCredits[item.id]}
                    </span>
                  </Tooltip>
                )}
              </div>
            ) : (
              <div style={{float: 'left', marginRight: '5px'}}>
                {loaderForId && loaderForId == item.id ? (
                  <Spin style={{position: 'relative', top: '5px'}} spinning={loaderForId}></Spin>
                ) : (
                  <Tooltip placement='bottom' title='Available Credit'>
                    <Button
                      shape='circle'
                      icon={<FontAwesomeIcon icon={faDollarSign} style={{color: '#1c1c1c'}} />}
                      onClick={() => {
                        this.checkAvailableCredits(item.id)
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            )}
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Preview'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showViewModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete users api
                    this.deleteUser(item)
                  }}
                  title='Are you sure you want to delete client?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {usersData, usersDataTotal, usersLoading} = this.props
    const {searchText, usersSelectedData} = this.state

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Clients'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                {/* <InputButtonComponent
                  onClick={() => {
                    this.onCreateUser()
                  }}
                >
                  Add User
                </InputButtonComponent> */}
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  paginationConfig={{
                    total: usersDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={usersLoading}
                  dataSource={usersData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={true}
                  scrollWidth={1200}
                />
                <div>
                  {/* <CommanModal
                    show={usersSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit User'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {usersSelectedData && (
                        <EditUserForm
                          data={usersSelectedData}
                          onSubmit={(values: any, userId: string) => {
                            this.onEditSubmit(values)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal> */}
                  <CommanModal
                    show={this.state.viewUserSelectedData ? true : false}
                    handleClose={() => {
                      this.closeViewModal()
                    }}
                    title={'Client Details'}
                    submitText={'Save Changes'}
                    isScrollable={true}
                  >
                    <div>
                      {this.state.viewUserSelectedData && (
                        <ViewUsersDetails
                          data={this.state.viewUserSelectedData}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  usersData: state.users.usersData,
  usersDataTotal: state.users.usersDataTotal,
  usersLoading: state.users.usersLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListUsersData: (usersData: any) => {
      dispatch(users.actions.setUsersData(usersData))
    },
    setListUsersDataTotal: (usersDataTotal: number) => {
      dispatch(users.actions.setUsersDataTotal(usersDataTotal))
    },
    setUsersLoading: (usersLoading: boolean) => {
      dispatch(users.actions.setUsersLoading(usersLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListUsersData))
