import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_SALES_REPORT_URL = `${API_URL}api/v1/auth/admin/sales-reporting`
export const GET_VENDOR_SALES_REPORT_URL = `${API_URL}api/v1/auth/admin/vendor-sales-reporting`

export async function getSalesReportData(skip: any, limit: any, start_date: any, end_date: any) {
  try {
    let salesReportData = await axios.get(
      `${GET_SALES_REPORT_URL}?skip=${skip}&limit=${limit}&start_date=${start_date}&end_date=${end_date}`
    )
    return salesReportData
  } catch (e) {
    console.log(e, 'error in api call')
  }
}

export async function getVendorSalesReportData(
  skip: any,
  limit: any,
  start_date: any,
  end_date: any
) {
  try {
    let salesReportData = await axios.get(
      `${GET_VENDOR_SALES_REPORT_URL}?skip=${skip}&limit=${limit}&start_date=${start_date}&end_date=${end_date}`
    )
    return salesReportData
  } catch (e) {
    console.log(e, 'error in api call')
  }
}
