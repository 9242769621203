/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getJewelleryShowcaseVideosData} from '../redux/JewelleryShowcaseVideosCRUD'
import {useDispatch} from 'react-redux'
import * as jewelleryShowcaseVideos from '../redux/JewelleryShowcaseVideosRedux'
import ListJewelleryShowcaseVideosData from './ListJewelleryShowcaseVideosData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 10,
  })

  const callJewelleryShowcaseVideosData = async () => {
    try {
      await dispatch(jewelleryShowcaseVideos.actions.setJewelleryShowcaseVideosLoading(true))

      let jewelleryShowcaseVideosData = await getJewelleryShowcaseVideosData(
        searchPagination.skip,
        searchPagination.limit
      )
      if (jewelleryShowcaseVideosData.data) {
        await dispatch(jewelleryShowcaseVideos.actions.setJewelleryShowcaseVideosLoading(false))
        // once jewelleryShowcaseVideos data set then loading will automatically off using saga middleware
        await dispatch(
          jewelleryShowcaseVideos.actions.setJewelleryShowcaseVideosData(
            jewelleryShowcaseVideosData.data
          )
        )
        await dispatch(
          jewelleryShowcaseVideos.actions.setJewelleryShowcaseVideosDataTotal(
            jewelleryShowcaseVideosData.count
          )
        )
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callJewelleryShowcaseVideosData()
  }, [])

  return (
    <div>
      <ListJewelleryShowcaseVideosData />
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/jewelleryShowcaseVideos/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/jewelleryShowcaseVideos/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.JEWELLERYSHOWCASEVIDEO'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
