import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setDashboardData: 'SET_DASHBOARD_DATA',
  setDashboardLoading: 'SET_DASHBOARD_LOADER',
  setSandboxStatus: 'SET_SANDBOX_STATUS', // New action type
}

const initialClientState: IClientState = {
  dashboardData: undefined,
  dashboardLoading: false,
  sandboxStatus: false, // New state property
}

export interface IClientState {
  dashboardData?: any
  dashboardLoading?: boolean
  sandboxStatus?: boolean // New boolean property
}

export const reducer = persistReducer(
  {storage, key: 'dashboard', whitelist: ['dashboardData', 'sandboxStatus']},
  (state: IClientState = initialClientState, action: ActionWithPayload<IClientState>) => {
    switch (action.type) {
      case actionTypes.setDashboardData: {
        const dashboardData = action.payload?.dashboardData
        return {...state, dashboardData}
      }

      case actionTypes.setDashboardLoading: {
        const dashboardLoading = action.payload?.dashboardLoading
        return {...state, dashboardLoading}
      }

      case actionTypes.setSandboxStatus: {
        console.log('Reducer: setSandboxStatus called with:', action.payload) // Log in reducer

        const sandboxStatus = action.payload?.sandboxStatus
        console.log({state, sandboxStatus}) // Log in reducer

        return {...state, sandboxStatus}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setDashboardData: (dashboardData: Array<any>) => ({
    type: actionTypes.setDashboardData,
    payload: {dashboardData},
  }),
  setDashboardLoading: (dashboardLoading: boolean) => ({
    type: actionTypes.setDashboardLoading,
    payload: {dashboardLoading},
  }),
  setSandboxStatus: (sandboxStatus: boolean) => {
    console.log('Action: setSandboxStatus dispatched with:', sandboxStatus) // Log in action
    return {
      type: actionTypes.setSandboxStatus,
      payload: {sandboxStatus},
    }
  },
}

export function* saga() {
  yield takeLatest(actionTypes.setDashboardData, function* setDashboardData() {
    yield put(actions.setDashboardLoading(false))
  })
}
