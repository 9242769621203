/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useHistory} from 'react-router'
import StatisticCard from '../../../component/dashboard/containers/StatasticsCard'

type Props = {
  icon: string
  title: string
  value: string
  path?: string
  background?: string
  subValue?: any
}

const KpiCardComponent: React.FC<Props> = ({
  icon,
  title,
  value,
  path,
  background = 'white',
  subValue,
}) => {
  const history = useHistory()
  const navigateToPath = () => {
    if (path) {
      history.push(path)
    }
  }

  return (
    <div className='col-xl-3' onClick={() => navigateToPath()}>
      <a
        href='javascript:void(0)'
        className={`card hoverable card-xl-stretch mb-xl-8 ${
          background === 'blue'
            ? 'bg-info'
            : background === 'black'
            ? 'bg-dark'
            : background === 'yellow'
            ? 'bg-warning'
            : 'bg-body'
        }`}
      >
        <div
          className='card-body'
          style={{
            display: 'flex',
            gap: '39px',
          }}
        >
          <div>
            <span className='svg-icon svg-icon-primary svg-icon-3x ms-n1'>
              <svg xmlns={icon} width={24} height={24} viewBox='0 0 24 24' fill='none'>
                <rect x={8} y={9} width={3} height={10} rx='1.5' fill='black' />
                <rect opacity='0.5' x={13} y={5} width={3} height={14} rx='1.5' fill='black' />
                <rect x={18} y={11} width={3} height={8} rx='1.5' fill='black' />
                <rect x={3} y={13} width={3} height={6} rx='1.5' fill='black' />
              </svg>
            </span>
            <div
              className={`${
                background === 'white' ? 'text-gray-900' : 'text-white'
              } fw-bolder fs-2 mb-2 mt-5`}
            >
              {value}
            </div>
            <div
              className={`${
                background === 'white'
                  ? 'text-white fw-bold text-gray-400'
                  : 'text-white text-gray-100'
              }`}
            >
              {title}
            </div>
          </div>
          {subValue && Array.isArray(subValue) && subValue.length > 0 && (
            <div
              className={`${background === 'white' ? 'text-gray-500' : 'text-gray-200'} fs-6 mt-2`}
            >
              {subValue.map((item, index) => (
                <StatisticCard
                  key={index}
                  title={item.status}
                  value={item.count}
                  titleColor={background === 'white' ? 'gray-500' : 'text-light'}
                />
              ))}
            </div>
          )}
        </div>
      </a>
    </div>
  )
}

export {KpiCardComponent}
