/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ListWraaper} from './container/List'
import PreviewOrderDetailsPage from '../melee/containers/PreviewOrderDetailsPage'
import ListTrailsData from './container/ListTrailsData'

export function ClientOfferPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/clientoffer/list' exact key='clientoffer-list' component={ListWraaper} />
        <Route
          path='/clientoffer/vendor/list'
          exact
          key='clientoffer-vendor-list'
          component={ListWraaper}
        />
        <Route
          path='/clientoffer/vendor/trails/:id'
          // exact={true}
          exact
          key='clientoffer-vendor-list-trails'
          component={ListTrailsData}
        />
        <Redirect from='/clientoffer' to='/clientoffer/list' />

        <Redirect to='/clientoffer/list' />
      </Switch>
    </div>
  )
}
