import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_JEWELRY_ORDER_URL = `${API_URL}api/v1/auth/admin/unified-order`
export const PREVIEW_ORDER_DETAILS = `${API_URL}api/v1/auth/admin/unified-order-details`
export const EDIT_ORDER_DETAILS_URL = `${API_URL}api/v1/auth/admin/unified-order-status`
export const CANCEL_ORDER_URL = `${API_URL}api/v1/auth/admin/unified-cancel-order`

interface ListType {
  count?: any
  data: any
}

// Get Order Data
export async function getJewelryOrderData(
  startIndex: number,
  limitNumber: number,
  status?: string
) {
  try {
    let getOrderAPIPath = ''
    if (status !== undefined && status !== '') {
      getOrderAPIPath = `${GET_JEWELRY_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&order_status=${status}&unified_order_type=jewellery`
    } else {
      getOrderAPIPath = `${GET_JEWELRY_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&order_status=PENDING&unified_order_type=jewellery`
    }
    let orderData: ListType = await axios.get(getOrderAPIPath)
    // let orderData: ListType = await axios.get(`${GET_VENDOR_ORDER_URL}`)
    return orderData
  } catch (e) {
    throw e
  }
}

export async function getOrderStockDetails(req_id: string) {
  try {
    let orderDetailsData: ListType = await axios.get(`${PREVIEW_ORDER_DETAILS}?id=${req_id}`)
    return orderDetailsData
  } catch (e) {
    throw e
  }
}

export async function changeOrderStausDataApi(values: any) {
  let editUserData = await axios.put(`${EDIT_ORDER_DETAILS_URL}`, {
    ...values,
  })
  return editUserData
}

export async function cancelOrderDataApi(data: any) {
  try {
    let cancelledOrderData = await axios.put(`${CANCEL_ORDER_URL}`, {
      id: data.itemId,
      cancel_reason: data.reject_reason,
    })
    return cancelledOrderData
  } catch (e) {
    console.log(e, 'error! in cancel Order Api')
  }
}
