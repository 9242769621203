/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {CreateWrapper} from './containers/CreateJewelleryShowcaseVideos'
import {ListWrapper} from './containers/List'
// import {PreviewWrapper} from './containers/Preview'

export function JewelleryShowcaseVideosPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/jewelleryShowcaseVideos/list' exact={true} component={ListWrapper} />
        <Route path='/jewelleryShowcaseVideos/create' exact={true} component={CreateWrapper} />
        <Redirect from='/jewelleryShowcaseVideos' exact={true} to='/jewelleryShowcaseVideos/list' />
        <Redirect to='/jewelleryShowcaseVideos/list' />
      </Switch>
    </div>
  )
}
