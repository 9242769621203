/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {ChangePassword} from './ChangePassword'
import {Tabs} from 'antd'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {ChangeEmail} from './ChangeEmail'
import {AdminBillingDetails} from './AdminBillingDetails'
import {KycUpload} from './KycUpload'
import {changeFtpPassword} from '../../../modules/auth/redux/AuthCRUD'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import * as auth from '../../../modules/auth/redux/AuthRedux'

const DisplaySetting: FC = () => {
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const authData: any = useSelector<RootState>(({auth}) => auth, shallowEqual)
  console.log({authData})
  const {role, ftp_password} = user
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [oldPasswordError, setOldPasswordError] = useState('')
  const dispatch = useDispatch()

  console.log({ftp_password})
  useEffect(() => {
    if (!isModalOpen) {
      setNewPassword('')
      setConfirmPassword('')
      setError('')
      setOldPasswordError('')
      setOldPassword('')
    }
  }, [isModalOpen])

  const handleChangePassword = async () => {
    console.log('handleChangePassword', {oldPassword}, {newPassword}, {confirmPassword})
    try {
      if (!oldPassword) {
        setOldPasswordError('Enter Old password')
        return
      }
      if (!newPassword || !confirmPassword) {
        setError('Enter Passwords')
        return
      }

      if (newPassword !== confirmPassword) {
        setError('Passwords do not match')
        return
      }

      // onChangePassword(newPassword)
      console.log({newPassword})
      const res: any = await changeFtpPassword(
        // values.email,
        oldPassword,
        newPassword
      )

      Toaster({
        type: 'success',
        title: 'Change FTP password',
        description: res?.message ?? '',
      })
      if (res?.status === 200) {
        if (authData?.user) {
          const updatedUser = {...authData.user, ftp_password: newPassword} // Create a new object
          const updatedAuthData = {...authData, user: updatedUser} // Update authData immutably

          console.log({userftp: updatedAuthData.user})

          dispatch(auth.actions.login(updatedAuthData.accessToken, updatedAuthData.user))
        }
      }

      setIsModalOpen(false)
    } catch (error) {
      console.error({error})
      setIsModalOpen(false)
    }
  }
  return (
    <div>
      <div style={{marginBottom: '30px'}}>
        <ChangeEmail />
        <ChangePassword />
        <KycUpload /> {role === 'super_admin' && <AdminBillingDetails />}
      </div>
      {role === 'vendor' && (
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>FTP Details</h3>
          </div>
          <div className='card-body'>
            <div className='d-flex flex-stack mb-2'>
              <div className='mb-3'>
                <label className='form-label text-dark fw-bold d-block mb-2'>
                  FTP Username:
                  <span className='text-muted fs-6'> {user.ftp_username || 'N/A'}</span>
                </label>
              </div>
            </div>
            <div className='d-flex flex-stack mb-2'>
              <div className='mb-3'>
                <label className='form-label text-dark fw-bold d-block mb-2'>
                  FTP Password:
                  <span className='text-muted fs-6'> {user.ftp_password || 'N/A'}</span>
                </label>
              </div>
            </div>
            <button className='btn btn-primary' onClick={() => setIsModalOpen(true)}>
              Change FTP Password
            </button>
          </div>

          {isModalOpen && (
            <div className='modal d-block' tabIndex={-1}>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>Change FTP Password</h5>
                    <button
                      type='button'
                      className='btn-close'
                      onClick={() => setIsModalOpen(false)}
                    ></button>
                  </div>
                  <div className='modal-body d-flex flex-column gap-3'>
                    <input
                      type='password'
                      className='form-control mb-2'
                      placeholder='Enter old password'
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    {oldPasswordError && <div className='text-danger'>{oldPasswordError}</div>}

                    <input
                      type='password'
                      className='form-control'
                      placeholder='Enter new password'
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <input
                      type='password'
                      className='form-control mb-2'
                      placeholder='Confirm new password'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {error && <div className='text-danger'>{error}</div>}
                  </div>
                  <div className='modal-footer'>
                    <button className='btn btn-secondary' onClick={() => setIsModalOpen(false)}>
                      Cancel
                    </button>
                    <button className='btn btn-primary' onClick={handleChangePassword}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export {DisplaySetting}
