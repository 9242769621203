import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setJewelleryShowcaseVideosData: 'SET_JEWELLERY_SHOWCASE_VIDEOS_DATA',
  setJewelleryShowcaseVideosDataTotal: 'SET_JEWELLERY_SHOWCASE_VIDEOS_DATA_TOTAL',
  setJewelleryShowcaseVideosLoading: 'SET_JEWELLERY_SHOWCASE_VIDEOS_LOADER'
}

const initialClientState: IJewelleryShowcaseVideosState = {
  jewelleryShowcaseVideosData: undefined,
  jewelleryShowcaseVideosDataTotal: undefined,
  jewelleryShowcaseVideosLoading: false
}

export interface IJewelleryShowcaseVideosState {
  jewelleryShowcaseVideosData?: any
  jewelleryShowcaseVideosDataTotal?: number
  jewelleryShowcaseVideosLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'jewelleryShowcaseVideos', whitelist: ['jewelleryShowcaseVideosData', 'jewelleryShowcaseVideosDataTotal']},
    (state: IJewelleryShowcaseVideosState = initialClientState, action: ActionWithPayload<IJewelleryShowcaseVideosState>) => {
    switch (action.type) {
      case actionTypes.setJewelleryShowcaseVideosData: {
        const jewelleryShowcaseVideosData = action.payload?.jewelleryShowcaseVideosData
        return {...state, jewelleryShowcaseVideosData}
      }
            
      case actionTypes.setJewelleryShowcaseVideosDataTotal: {
        const jewelleryShowcaseVideosDataTotal = action.payload?.jewelleryShowcaseVideosDataTotal
        return {...state, jewelleryShowcaseVideosDataTotal}
      }
        
      case actionTypes.setJewelleryShowcaseVideosLoading: {
        const jewelleryShowcaseVideosLoading = action.payload?.jewelleryShowcaseVideosLoading
        return {...state, jewelleryShowcaseVideosLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setJewelleryShowcaseVideosData: (jewelleryShowcaseVideosData: Array<any>) => ({type: actionTypes.setJewelleryShowcaseVideosData, payload: {jewelleryShowcaseVideosData : jewelleryShowcaseVideosData}}),
  setJewelleryShowcaseVideosDataTotal: (jewelleryShowcaseVideosDataTotal: number) => ({ type: actionTypes.setJewelleryShowcaseVideosDataTotal, payload: { jewelleryShowcaseVideosDataTotal } }),
  setJewelleryShowcaseVideosLoading: (jewelleryShowcaseVideosLoading: boolean) => ({type: actionTypes.setJewelleryShowcaseVideosLoading, payload: {jewelleryShowcaseVideosLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setJewelleryShowcaseVideosData, function* loginSaga() {
    yield put(actions.setJewelleryShowcaseVideosLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
