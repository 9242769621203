import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'

interface IRenderFormikForm {
  values: IJewelleryShowcaseVideoFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IJewelleryShowcaseVideoFormValues {
  first_name: string
  last_name: string
  email: string
  password: string
  JewelleryShowcaseVideo_type: string
  company_name: string
  api_url?: string
  margin_percentage?: number
}

interface IAddJewelleryShowcaseVideoProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

export class CreateJewelleryShowcaseVideoForm extends React.Component<IAddJewelleryShowcaseVideoProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IJewelleryShowcaseVideoFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.first_name) {
      errors.first_name = 'First name is required'
    }

    if (!values.last_name) {
      errors.last_name = 'Last name is required'
    }

    if (!values.email) {
      errors.email = 'Email is required'
    }

    if (
      values.email &&
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(values.email).toLowerCase()
      )
    ) {
      errors.email = 'Please Provide Valid Email'
    }

    if (!values.password) {
      errors.password = 'Password is required'
    }
    if (
      values.password &&
      !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.])[A-Za-z\d!@#$%^&*.]{6,}$/.test(values.password)
    ) {
      errors.password =
        'Password must have at least 6 characters, including one uppercase letter, one number, and at least one special character'
    }

    if (!values.JewelleryShowcaseVideo_type) {
      errors.JewelleryShowcaseVideo_type = 'JewelleryShowcaseVideo Type is required'
    }

    if (!values.company_name) {
      errors.company_name = 'Company Name is required'
    }

    // if (values.JewelleryShowcaseVideo_type && values.JewelleryShowcaseVideo_type == 'API' && !values.api_url) {
    //   errors.api_url = 'Api Url is required'
    // }

    if (values.margin_percentage && values.margin_percentage < 0) {
      errors.margin_percentage = 'Margin must be Positive'
    }

    if (values.margin_percentage && values.margin_percentage > 100) {
      errors.margin_percentage = 'Margin must be less than 100'
    }

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = (values: IJewelleryShowcaseVideoFormValues, action: any) => {
    if (values.JewelleryShowcaseVideo_type && values.JewelleryShowcaseVideo_type !== 'API' && 'api_url' in values) {
      delete values.api_url
    }
    console.log('Basic Value - ', values)
    // values.phone_iso = values.phone.split('-').length > 0 ? values.phone.split('-')[0] : ''
    this.props.onSubmit(values)
    this.props.handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>Create JewelleryShowcaseVideo Form</h3>
          </div>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  first_name: '',
                  last_name: '',
                  email: '',
                  JewelleryShowcaseVideo_type: '',
                  api_url: '',
                  password: '',
                  company_name: '',
                  margin_percentage: 0,
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.first_name,
                              id: 'first_name',
                              name: 'first_name',
                            }}
                            placeholder='Enter first name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('first_name', value)
                            }}
                            onBlur={handleBlur}
                            label='First Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.last_name,
                              id: 'last_name',
                              name: 'last_name',
                            }}
                            placeholder='Enter last name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('last_name', value)
                            }}
                            onBlur={handleBlur}
                            label='Last Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.email,
                              id: 'email',
                              name: 'email',
                            }}
                            placeholder='Enter email'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('email', value)
                            }}
                            onBlur={handleBlur}
                            label='Email'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.password,
                              id: 'password',
                              name: 'password',
                            }}
                            placeholder='Enter password'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('password', value)
                            }}
                            onBlur={handleBlur}
                            label='Password'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        {/* <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.JewelleryShowcaseVideo_type,
                              id: 'JewelleryShowcaseVideo_type',
                              name: 'JewelleryShowcaseVideo_type',
                            }}
                            placeholder='Enter JewelleryShowcaseVideo Type'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('JewelleryShowcaseVideo_type', value)
                            }}
                            onBlur={handleBlur}
                            label='JewelleryShowcaseVideo Type'
                            error={errors}
                            touched={touched}
                          />
                        </div> */}
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.company_name,
                              id: 'company_name',
                              name: 'company_name',
                            }}
                            placeholder='Enter Company Name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('company_name', value)
                            }}
                            onBlur={handleBlur}
                            label='Company Name'
                            error={errors}
                            touched={touched}
                            required={false}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputSingleSelect
                            input={{
                              //   value: values.JewelleryShowcaseVideo_type,
                              id: 'JewelleryShowcaseVideo_type',
                              name: 'JewelleryShowcaseVideo_type',
                              options: [
                                {
                                  label: 'API',
                                  value: 'API',
                                },
                                {
                                  label: 'UPLOAD',
                                  value: 'UPLOAD',
                                },
                                {
                                  label: 'FTP',
                                  value: 'FTP',
                                },
                              ],
                            }}
                            placeholder='Select Stock Upload type'
                            onChangeMethod={(value: string) => {
                              setFieldValue('JewelleryShowcaseVideo_type', value)
                            }}
                            label='Stock Upload type'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.margin_percentage,
                              id: 'margin_percentage',
                              name: 'margin_percentage',
                            }}
                            placeholder='Enter Margin Percentage'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('margin_percentage', value)
                            }}
                            onBlur={handleBlur}
                            label='Margin (%)'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        {values.JewelleryShowcaseVideo_type == 'API' && (
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.api_url,
                                id: 'api_url',
                                name: 'api_url',
                              }}
                              placeholder='Enter Api Url'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('api_url', value)
                              }}
                              onBlur={handleBlur}
                              label='Api Url'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                        )}
                      </div>
                      <InputButtonComponent onClick={handleSubmit}>Create</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
