import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as client from '../../app/component/client'
import * as dashboard from '../../app/component/dashboard'
import * as inventory from '../../app/component/inventory'
import * as users from '../../app/component/users'
import * as vendors from '../../app/component/vendors'
import * as subadmin from '../../app/component/subadmin'
import * as buyRequest from '../../app/component/buyRequest'
import * as vendorstock from '../../app/component/vendorStocks'
import * as banner from '../../app/component/banner'
import * as order from '../../app/component/order'
import * as vendororder from '../../app/component/vendororder'
import * as kyc from '../../app/component/kyc'
import * as returnorder from '../../app/component/returnorder'
import * as chats from '../../app/component/chats'
import * as clientoffer from '../../app/component/clientoffers'
import * as staticpage from '../../app/component/static'
import * as sales from '../../app/component/salesReport'
import * as declinestock from '../../app/component/declineStocks'
import * as jewelryorder from '../../app/component/jewelryOrder'
import * as meleeorder from '../../app/component/melee'
import * as jewelleryShowcaseVideos from '../../app/component/jewelleryShowcaseVideos'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  client: client.reducer,
  dashboard: dashboard.reducer,
  inventory: inventory.reducer,
  users: users.reducer,
  vendors: vendors.reducer,
  subadmin: subadmin.reducer,
  buyRequest: buyRequest.reducer,
  vendorstock: vendorstock.reducer,
  banner: banner.reducer,
  order: order.reducer,
  vendororder: vendororder.reducer,
  kyc: kyc.reducer,
  returnorder: returnorder.reducer,
  chats: chats.reducer,
  clientoffer: clientoffer.reducer,
  staticpage: staticpage.reducer,
  sales: sales.reducer,
  declinestock: declinestock.reducer,
  jewelryorder: jewelryorder.reducer,
  meleeorder:meleeorder.reducer,
  jewelleryShowcaseVideos:jewelleryShowcaseVideos.reducer

})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    client.saga(),
    dashboard.saga(),
    inventory.saga(),
    users.saga(),
    vendors.saga(),
    subadmin.saga(),
    buyRequest.saga(),
    vendorstock.saga(),
    banner.saga(),
    order.saga(),
    vendororder.saga(),
    kyc.saga(),
    returnorder.saga(),
    chats.saga(),
    clientoffer.saga(),
    staticpage.saga(),
    sales.saga(),
    declinestock.saga(),
    jewelryorder.saga(),
    meleeorder.saga(),
  ])
}
